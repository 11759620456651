import React, { Component } from "react";
import update from "immutability-helper";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import cookie from "react-cookies";
import axios from "axios";
import Swal from "sweetalert2";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import { Rating } from "react-simple-star-rating";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { GET_CUSTOMER_DETAILS } from "../../actions";
import { apiUrl, unquieID, headerconfig, baseUrl } from "../Settings/Config";
import {
  hideLoader,
  showAlert,
  showLoader,
  stripslashes,
  showPriceValue,
  showPriceSeparate,
  showPrice,
  showLoaderLst,
  hideLoaderLst
} from "../Helpers/SettingHelper";
import arrow from "../../common/images/success-tick.svg";
import offerimage from "../../common/images/offerimage.png";
import voucher2 from "../../common/images/voucher2.png";
import cellphone1 from "../../common/images/cellphone1.svg";
import Header from "../Layout/Header";
var qs = require("qs");
var base64 = require("base-64");
class ThankyouDirectPay extends Component {
  constructor(props) {
    super(props);
    const UserId = cookie.load("UserId") || "";

    const loginType = cookie.load("loginType") || "";
    var ratingName = '';
    var ratingEmail = '';
    var ratingPhone = '';
    if(loginType !== "Guest") {
      ratingName = cookie.load("UserFname") || "";
      ratingEmail = cookie.load("UserEmail") || "";
      ratingPhone = cookie.load("UserMobile") || "";      
    }

    var refcode =
      localStorage.getItem("refcode") === null
        ? ""
        : localStorage.getItem("refcode");
    var triggerOTP = "No";
    var triggerFrom = "";
    var bottompopup = "regphone";
    var reg_phone_no = "";
    if (cookie.load("triggerOTP") == "Yes") {
      triggerOTP = "Yes";
      triggerFrom = cookie.load("triggerFrom");
      reg_phone_no = cookie.load("UserMobile");
      bottompopup = "regotp";
    }

    this.state = {
      designType: "new",

      billPayAmount: "",
      current_page: "Direct Pay Thankyou",
      checkoutType: "BillPay",
      clientLogo: "",
      backLink: "",
      UserId: UserId,
      customerData: [],

      plan_gst_amount: 0,
      plan_grandtotal: 0,
      inititalLoad: true,
      productList: [],
      productName: "",
      gSettings: "",
      paymentDetail: "",
      googleRating: false,

      ratingAlreadyPost:"No",
      ratingValue: 0,
      ratingName: ratingName,
      ratingEmail: ratingEmail!==""?base64.decode(ratingEmail):'',
      ratingPhone: ratingPhone,
      ratingMessage: "",


      loginType: loginType,
      runingNum: 0,
      bottompopup: bottompopup,
      trigger_otp: triggerOTP,
      trigger_from: triggerFrom,
      reg_phone_no: reg_phone_no,
      regphone_error: "",
      regotpdata: {
        reg_otp_dgt1: "",
        reg_otp_dgt2: "",
        reg_otp_dgt3: "",
        reg_otp_dgt4: "",
      },
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      regcust_error: "",
      openLogin: false,
      welcomeVoucher: [],
      selectedVoucher:""
    };
    if (UserId === "") {
      props.history.push("/");
    }
    this.submitRef = React.createRef();
    this.props.getCustomerDetails("&customer_id=" + UserId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    cookie.remove('DirectQRCode', { path: "/" });
    var billPayAmount = this.props.location.state?.billPayAmount || "";
    if(billPayAmount!=="") {
      this.loadDatas();
    }else {
      
      this.checkPaymentStatus();
    }
    this.loadSettings();
    this.loadWelcomeVoucher();
    console.log(this.props, 'this.props')
    
    /* */
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails });
    }
  }
  checkPaymentStatus() {
    var urlParams = new URLSearchParams(this.props.location.search);
    var transactionID = urlParams.get("transaction_id") || "";

    var postObject = {};
    postObject = {
      app_id: unquieID,
      payment_refID: transactionID,
      customer_id: this.state.UserId,
    };
    axios
      .post(
        apiUrl + "paymentV2/checkStatusReddot",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          const billData = res.data.billData
          const ratingAlreadyPost = billData.ratingAlreadyPost;
          var ratingValue = 0;
          if(ratingAlreadyPost==="Yes") {
            ratingValue = billData.ratingNumber;
          }

          this.setState({ paymentDetail: res.data.billData, ratingValue:ratingValue,ratingAlreadyPost:ratingAlreadyPost });
        } else {
          Swal.fire({
            title: "Error",
            html: res.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.props.history.push("/directpay/checkout");
        }
      });
  }

  loadDatas() {
    var state = this.props.location.state || "";
    var voucherList = state?.voucherList || [];
    var selectedVoucher = state?.selectedVoucher;
    let paymentDetail ={
      outletName:state?.companyName,
      reciveAmount:state?.reciveAmount,
      total_amount:state?.plan_grandtotal,
      billPayAmount:state?.billPayAmount,
      gstAmount:0,
      productID:(selectedVoucher!=="")?voucherList[selectedVoucher].product_id:'',
    };
    this.setState({paymentDetail:paymentDetail})
  }

  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?unquieID=" + unquieID;
    var outletID = cookie.load('outletID') || '';
    if(outletID!=="") {
      urlShringTxt+='&outletID='+outletID
    }
    axios.get(urlShringTxt, headerconfig).then((res) => {
      if (res.data.status === "ok") {
        var result = res.data.result;

        this.setState({
          gSettings: result,
          clientLogo: result?.company_logo,
        });
      }
    });
  }
  loadWelcomeVoucher() {
    var customerID = cookie.load("UserId") || "";
    var urlShringTxt =
      apiUrl +
      "products/products_list/?app_id=" +
      unquieID +
      "&welcomevoucher=1&customerId=" +
      customerID;
    axios
      .get(urlShringTxt, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            welcomeVoucher: res.data.result_set,
          });
        }
      });
  }
  

  handleRating(rate) {
    if(this.state.ratingAlreadyPost==="No") {
      this.setState({ ratingValue: rate });
    }
    
  }
  submitReview(e) {
    e.preventDefault();
    if (this.state.ratingValue > 3) {
      this.submitRating();
    } else {
      this.setState({ googleRating: true });
    }
  }


  submitRating() {
    const postObject = {
      app_id:unquieID,
      custID:this.state.UserId,
      ratingNumber:this.state.ratingValue,
      ratingName:this.state.ratingName,
      ratingEmail:this.state.ratingEmail,
      ratingPhone:this.state.ratingPhone,
      ratingMessage:this.state.ratingMessage,
      outletID:cookie.load('outletID')||''
    };

    axios
      .post(
        apiUrl + "customer/submitRating",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ googleRating: false, ratingAlreadyPost:"Yes" });

          var gSettings = this.state.gSettings;
          var placeID = gSettings?.outlet?.placeID || '';
          if(placeID=="") {
            placeID = gSettings?.placeID || '';
          }

          if(this.state.ratingValue>3 && placeID!=="") {
            window.open(
              `https://search.google.com/local/writereview?placeid=${placeID}`,
              "_blank"
            );
          }else {            
            Swal.fire({
              title: "Success",
              html: res.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
          }
        } else {
          this.setState({ googleRating: false });
          Swal.fire({
            title: "Error",
            html: res.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
        }
      });
  }

  gotoWelcomeVoucher(selectedVoucher, e) {
    console.log(selectedVoucher, 'selectedVoucher')
    if(e!=="" && typeof e!==undefined && typeof e!==undefined) {
      e.preventDefault();
    }
    if(selectedVoucher.alreadyredeem==="no") {
      if(this.state.loginType === "Guest") {
        this.setState({selectedVoucher:selectedVoucher, openLogin: true})
      }else {
        this.props.history.push("/buy-vouchers/" + selectedVoucher.product_slug);
      }
    }
  }


  handleFldChange(event) {
    const re = /^[0-9 \b]+$/;
    if (event.target.name === "reg_phone_no") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumber = this.space(event.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 9) {
          this.setState({ reg_phone_no: mblnumber });
        }
      }
    } else if (
      event.target.name === "reg_otp_dgt1" ||
      event.target.name === "reg_otp_dgt2" ||
      event.target.name === "reg_otp_dgt3" ||
      event.target.name === "reg_otp_dgt4"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumber = this.space(event.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 1) {
          let field = event.target.name;
          let fieldvalue = event.target.value;
          this.setState(
            update(this.state, {
              regotpdata: { [field]: { $set: fieldvalue } },
            }),
            function () {
              if (field === "reg_otp_dgt1" && mblnumberLenght == 1) {
                $("#reg_otp_dgt2").focus();
              }
              if (field === "reg_otp_dgt2" && mblnumberLenght == 1) {
                $("#reg_otp_dgt3").focus();
              }
              if (field === "reg_otp_dgt3" && mblnumberLenght == 1) {
                $("#reg_otp_dgt4").focus();
              }
            }
          );
        }
      }
    } else if (event.target.name === "cust_name") {
      this.setState({ cust_name: event.target.value });
    } else if (event.target.name === "cust_email_id") {
      this.setState({ cust_email_id: event.target.value });
    } else if (event.target.name === "birthday_month") {
      this.setState({ birthday_month: event.target.value });
    } else if (event.target.name === "cust_ref_code") {
      this.setState({ cust_ref_code: event.target.value });
    }else {
      var name = event.target.name;
      var value = event.target.value;
      this.setState({ [name]: value });
    }
    this.setState({ regphone_error: "", regotp_error: "", regcust_error: "" });
  }

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    var downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }

  triggerOTPFunction() {
    var regphoneNo = cookie.load("UserMobile");
    if (
      regphoneNo != "" &&
      typeof regphoneNo !== undefined &&
      typeof regphoneNo !== "undefined"
    ) {
      console.log(regphoneNo, "regphoneNo");
      regphoneNo = regphoneNo.replace(/ /g, "");
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
      };
      showLoaderLst("home-page-maindiv", "class");
      axios
        .post(apiUrl + "customer/send_otponly", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("home-page-maindiv", "class");
          if (res.data.status === "ok") {
            this.setState(
              { bottompopup: "regotp", runingNum: 120 },
              function () {
                this.setRuningNum();
              }.bind(this)
            );
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  regSendOtp(typeflg, event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    if (regphoneNo != "") {
      var postObject = {
        customer_mobile: regphoneNo,
        otp_type: typeflg,
      };
      if (unquieID !== "") {
        postObject["app_id"] = unquieID;
      }
      let loadrdivcls = typeflg == "login" ? "login-otp-link" : "reg-otp-link";
      showLoaderLst(loadrdivcls, "class");
      axios
        .post(apiUrl + "customer/send_customer_otp", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst(loadrdivcls, "class");
          if (res.data.status === "ok") {
            if (
              typeflg == "login" ||
              (typeflg == "register" && res.data.is_existing_user == "yes")
            ) {
              let custArr = res.data.result;
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("UserMobile", regphoneNo, { path: "/" });
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
              cookie.remove("loginType", { path: "/" });
              if (unquieID !== "") {
                localStorage.setItem("allowCompanyRedirect", "Yes");
              }
              let $_this = this;
              if (this.props.location?.state) {
                localStorage.setItem(
                  "voucherSlug",
                  $_this.props.location.state.voucherSlug
                );
                localStorage.setItem(
                  "voucherquantity",
                  $_this.props.location.state.quantity
                );
                setTimeout(function () {
                  if($_this.state.selectedVoucher!=="") {
                    window.location.href = baseUrl + "buy-vouchers/" + $_this.state.selectedVoucher.product_slug;
                  }else {
                    window.location.href = baseUrl + "directpay";
                  }

                  
                }, 500);
              } else {
                setTimeout(function () {
                  if($_this.state.selectedVoucher!=="") {
                    window.location.href = baseUrl + "buy-vouchers/" + $_this.state.selectedVoucher.product_slug;
                  }else {
                    window.location.href = baseUrl + "directpay";
                  }

                  //$_this.props.history.push("/myaccount");
                }, 100);
              }
            } else {
              /*var bottompopuptxt = (typeflg == 'login')? 'loginotp' : 'regotp';*/
              this.setState({ bottompopup: "confirm" });
            }
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  confirmPhone(event) {
    event.preventDefault();
    this.setState(
      { bottompopup: "regotp", runingNum: 120 },
      function () {
        this.setRuningNum();
      }.bind(this)
    );
  }

  displayRunNumbar() {
    if (this.state.regotp_error != "") {
      return <p className="error_info">{this.state.regotp_error}</p>;
    } else if (this.state.runingNum > 0) {
      return (
        <p className="runing_num">Resend in {this.state.runingNum} Seconds</p>
      );
    } else {
      return <p className="resend_link">Resend</p>;
    }
  }

  chakRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field == "trams") {
      var chkBox = regotp_terms == "Yes" ? true : false;
      return chkBox;
    } else if (field == "promo") {
      var chkBox = regotp_promo == "Yes" ? true : false;
      return chkBox;
    }
  }

  changeRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field == "trams") {
      var regotpterms = regotp_terms == "Yes" ? "No" : "Yes";
      this.setState({ regotp_terms: regotpterms });
      if (regotp_terms == "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }

    if (field == "promo") {
      var regotpPromo = regotp_promo == "Yes" ? "No" : "Yes";
      this.setState({ regotp_promo: regotpPromo });
      if (regotpPromo == "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }
  }

  cancelAct(event) {
    event.preventDefault();
    var triggerfrom = this.state.trigger_from;
    triggerfrom =
      triggerfrom != "" && triggerfrom != undefined ? triggerfrom : "myaccount";
    if (triggerfrom == "checkout") {
      triggerfrom = "myaccount";
    }
    this.props.history.push("/" + triggerfrom);
  }

  tgrVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    console.log(regphoneNo, "regphoneNo");
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "redeempoints",
        influencerID: localStorage.getItem("influencerID"),
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            var triggerfrom = this.state.trigger_from;
            triggerfrom =
              triggerfrom != "" && triggerfrom != undefined
                ? triggerfrom
                : "myaccount";
            let $_this = this;
            console.log(this.props, "this.props");
            if (this.props.location?.state) {
              setTimeout(function () {
                if($_this.state.selectedVoucher!=="") {
                  window.location.href = baseUrl + "buy-vouchers/" + $_this.state.selectedVoucher.product_slug;
                }else {
                  $_this.props.history.push({
                    pathname: $_this.props.location.state.triggerFrom,
                    state: $_this.props.location.state,
                  });
                }
              }, 500);
            } else {
              setTimeout(function () {
                if($_this.state.selectedVoucher!=="") {
                  window.location.href = baseUrl + "buy-vouchers/" + $_this.state.selectedVoucher.product_slug;
                }else {
                  $_this.props.history.push("/" + triggerfrom);
                }
              }, 500);
            }
            return false;
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  regVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    } else if (this.state.regotp_terms != "Yes") {
      errorMgs = "Terms & Conditions required";
    } else if (this.state.regotp_promo != "Yes") {
      errorMgs = "Promotions required";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "register",
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            this.setState({ bottompopup: "regpersonal", runingNum: 0 });
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  goBackTo(refPage, event) {
    event.preventDefault();
    var regotpdata = {
      reg_otp_dgt1: "",
      reg_otp_dgt2: "",
      reg_otp_dgt3: "",
      reg_otp_dgt4: "",
    };
    this.setState({
      bottompopup: refPage,
      reg_phone_no: "",
      regphone_error: "",
      regotpdata: regotpdata,
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      cust_ref_code: "",
      regcust_error: "",
    });
  }

  custRegistration(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var errorMgs = "";
    if (regphoneNo == "") {
      errorMgs = "Mobile No required";
    } else if (this.state.cust_name == "") {
      errorMgs = "Name required";
    } else if (this.state.cust_email_id == "") {
      errorMgs = "Email required";
    } else if (this.state.birthday_month == "") {
      errorMgs = "Birthday month required";
    }

    if (errorMgs == "") {
      var shareProductID =
        cookie.load("shareProductID") !== "" &&
        typeof cookie.load("shareProductID") !== undefined &&
        typeof cookie.load("shareProductID") !== "undefined"
          ? cookie.load("shareProductID")
          : "";
      var analyticsType =
        cookie.load("analyticsType") !== "" &&
        typeof cookie.load("analyticsType") !== undefined &&
        typeof cookie.load("analyticsType") !== "undefined"
          ? cookie.load("analyticsType")
          : "";
      var postObject = {
        app_id: unquieID,
        customer_phone: regphoneNo,
        customer_email: this.state.cust_email_id,
        customer_birthmonth: this.state.birthday_month,
        customer_ref_code: this.state.cust_ref_code,
        customer_name: this.state.cust_name,
        shareProductID: localStorage.getItem("shareProductID") || "",
        analyticsType: localStorage.getItem("analyticsType") || "",
      };
      showLoaderLst("cust-reg-btn", "class");
      axios
        .post(apiUrl + "customer/registration", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("cust-reg-btn", "class");
          if (res.data.status === "ok") {
            //this.setState({ bottompopup: 'regpersonal', runingNum: 0 });

            let custArr = res.data.result_set;
            cookie.save("UserId", custArr.customer_id, { path: "/" });
            cookie.save("UserEmail", custArr.customer_email, { path: "/" });
            cookie.save(
              "UserFname",
              custArr.customer_first_name !== ""
                ? custArr.customer_first_name
                : "",
              { path: "/" }
            );
            cookie.save(
              "UserLname",
              custArr.customer_last_name !== ""
                ? custArr.customer_last_name
                : "",
              { path: "/" }
            );
            cookie.save("UserMobile", regphoneNo, { path: "/" });
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            localStorage.setItem("token", custArr?.token);
            cookie.remove("loginType", { path: "/" });
            let $_this = this;
            setTimeout(function () {
              if($_this.state.selectedVoucher!=="") {
                window.location.href = baseUrl + "buy-vouchers/" + $_this.state.selectedVoucher.product_slug;
              }else {
                window.location.href = baseUrl + "directpay";
              }
            }, 100);
          } else {
            this.setState({ regcust_error: res.data.message });
          }
        });
    } else {
      this.setState({ regcust_error: errorMgs });
    }
  }

  loginVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "login",
      };
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ runingNum: 0 });
            if(this.state.selectedVoucher!=="") {
              window.location.href = baseUrl + "buy-vouchers/" + this.state.selectedVoucher.product_slug;
            }else {
              this.props.history.push("myaccount");
            }
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }
  openLogin(e) {
    e.preventDefault();
    this.setState({ openLogin: true });
  }

  render() {
    if (this.state.gSettings !== "" && this.state.paymentDetail !== "") {
      return (
        <div className="directpay-new home-page">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div className="container">
            <div className="pament-success">
              <div className="payment-confirmation">
                <div className="success-heading">
                  <div className="icon">
                    <img src={arrow} alt="" />
                  </div>

                  <h2>
                    Thank You For Your <br /> Payment
                  </h2>
                </div>
                <div className="details">
                  <div className="table-sec">
                   {/*  <div className="table-no">Table 5</div> */}
                    {this.state.paymentDetail?.outletName !== "" && (
                      <p>{this.state.paymentDetail?.outletName}</p>
                    )}
                  </div>
                 {/*  <div className="payment-info">
                    <div className="paid-info">
                      <div>
                        <strong>{this.state.paymentDetail.proName}</strong>
                      </div>
                      <div>
                        {" "}
                        <span>
                          1 X{" "}
                          {showPriceValue(
                            this.state.paymentDetail.subtotal_amount
                          )}
                        </span>
                      </div>
                    </div>
                     <p>You payed for your share</p>
                  </div> */}
                  {this.state.paymentDetail?.productID !== "" && (
                    <>
                      <div className="payment-info">
                        <div className="paid-info">
                          <div>
                            <strong>You Pay</strong>
                          </div>
                          <div>
                            {" "}
                            <span>
                              {" "}
                              {showPriceSeparate(
                                this.state.paymentDetail?.total_amount
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {this.state.paymentDetail?.reciveAmount !== "" && (
                        <div className="payment-info">
                          <div className="paid-info">
                            <div>
                              <strong>Receive</strong>
                            </div>
                            <div>
                              {" "}
                              <span>
                                {showPriceSeparate(
                                  this.state.paymentDetail?.reciveAmount || 0
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="payment-info">
                    <div className="paid-info">
                      <div>
                        <strong>Bill</strong>
                      </div>
                      <div>
                        {" "}
                        <span>
                          {showPriceSeparate(
                            this.state.paymentDetail.billPayAmount
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {parseFloat(this.state.paymentDetail?.gstAmount || 0) > 0 && (
                    <div className="payment-info">
                      <div className="paid-info">
                        <div>
                          <strong>GST</strong>
                        </div>
                        <div>
                          <span>
                            {showPriceSeparate(
                              this.state.paymentDetail?.gstAmount
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.paymentDetail?.productID !== "" && (
                    <div className="payment-info">
                      <div className="paid-info">
                        <div>
                          <strong>Balance For Next Visit</strong>
                        </div>
                        <div>
                          <span>
                            {showPriceSeparate(
                              this.state.paymentDetail?.reciveAmount -
                                this.state.paymentDetail.billPayAmount
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="payment-info">
                    <div className="paid-info">
                      <div>
                        <strong>Total</strong>
                      </div>
                      <div>
                        <span>
                          {showPriceSeparate(
                            this.state.paymentDetail.total_amount
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="review-section">
                <p>How's your overall experience?</p>
                <div className="stars">
                  <Rating
                    initialValue={this.state.ratingValue}
                    onClick={this.handleRating.bind(this)}
                    allowHover={this.state.ratingAlreadyPost==="Yes"?false:true}
                  />
                </div>
                {this.state.ratingAlreadyPost==="No" && (
                <a
                  href={"/"}
                  onClick={this.submitReview.bind(this)}
                  className="review-btn"
                >
                  Review This Place
                </a>)}
              </div>

              <div className="voucher-section">

              {this.state.welcomeVoucher.length > 0 &&
              this.state.welcomeVoucher.map((item) => {
                return (

                  <div className="voucher-card" >
                  <img
                    src={
                      item.product_thumbnail !== "" &&
                      item.product_thumbnail !== null
                        ? item.product_thumbnail: offerimage}
                      
                    alt="Voucher"
                    className="thanks-voucher-image"
                    onClick={this.gotoWelcomeVoucher.bind(this, item)} />
                  <a href="#" className={`voucher-btn ${item.alreadyredeem==="yes"?'used':''}`} onClick={this.gotoWelcomeVoucher.bind(this, item)}>
                    {item.alreadyredeem==="yes"?'Already issued':'Discover Our Vouchers'}
                  </a>
                </div>
                );
              })}

              {/*  <div className="promotion-section" onClick={this.gotoWelcomeVoucher.bind(this, item)}>
                    <img
                      src={
                        item.product_thumbnail !== "" &&
                        item.product_thumbnail !== null
                          ? item.product_thumbnail
                          : offerimage
                      }
                      alt="Breakfast offer"
                      className="promo-image"
                    />
                    <div className={`promo-text ${item.alreadyredeem==="yes"?'used':''}`}>
                      <p>{item.alreadyredeem==="yes"?'Already issued':'Sign up to collect your welcome voucher'}</p>
                    </div>
                  </div> */}
                {/* <div className="voucher-card">
                  <img
                    src={voucher2}
                    alt="Birthday Voucher"
                    className="thanks-voucher-image"
                  />
                  <a href="#" className="voucher-btn">
                    Claim Your Birthday Voucher
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <BottomSheet
            open={this.state.googleRating}
            onDismiss={() => {
              this.setState({ googleRating: false });
            }}
          >
            <div className="container">
              <div className="update-personal internal-rating">
                <div className="up-header textcenter">
                  <p>How’s your overall experience?</p>
                  <Rating
                    initialValue={this.state.ratingValue}
                    onClick={this.handleRating.bind(this)}
                  />
                </div>
                <div className="up-form">
                  <input
                    type="text"
                    placeholder="Name"
                    name="ratingName"
                    value={this.state.ratingName}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="ratingEmail"
                    value={this.state.ratingEmail}
                    onChange={this.handleFldChange.bind(this)}
                  />

                  <input
                    type="text"
                    placeholder="Contact Number"
                    name="ratingPhone"
                    value={this.state.ratingPhone}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <textarea
                    placeholder="Let us know your feedback"
                    name="ratingMessage"
                    onChange={this.handleFldChange.bind(this)}
                  >
                    {this.state.ratingMessage}
                  </textarea>
                  {this.state.regcust_error != "" && (
                    <p className="error_info">{this.state.regcust_error}</p>
                  )}
                  <div className="up-frm-btn">
                    <a
                      href={void 0}
                      className="button cust-reg-btn"
                      onClick={this.submitRating.bind(this)}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BottomSheet>
          <BottomSheet
          open={this.state.openLogin}
          onDismiss={() => {
            this.setState({ openLogin: false });
          }}
        >
          <div className="container">
            {this.state.bottompopup == "regphone" && (
              <div className="welcome-phone">
                <div className="wp-top">
                  <h2>Welcome to EpicPay</h2>
                  <p>Enter your phone number to create your account</p>
                </div>
                <div className="input-phone">
                  <div className="prefix-merge">
                    <div className="prefix">+65</div>
                    <div className="nxt-fix">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_phone_no"
                        value={this.state.reg_phone_no}
                        placeholder="Phone number"
                        onChange={this.handleFldChange.bind(this)}
                      />
                    </div>
                  </div>
                  {this.state.regphone_error != "" && (
                    <span className="error-info">
                      {this.state.regphone_error}
                    </span>
                  )}
                  <p>
                    We will send you a one-time SMS, Additional carriers charges
                    may apply
                  </p>
                </div>
                <div className="bottom-btn bb-txt textcenter">
                  <a
                    href={void 0}
                    className="button full-btn reg-otp-link"
                    onClick={this.regSendOtp.bind(this, "register")}
                  >
                    Send OTP
                  </a>
                  <p>
                    Do you have an account ?{" "}
                    <a
                      href={void 0}
                      onClick={this.goBackTo.bind(this, "loginfrm")}
                    >
                      Login here
                    </a>
                  </p>
                </div>
              </div>
            )}

            {this.state.bottompopup == "confirm" && (
              <div className="update-personal">
                <div className="up-header confirm-header textcenter">
                  <h2>
                    You have entered<br></br>+65 {this.state.reg_phone_no}
                  </h2>
                  <img src={cellphone1} />
                </div>
                <div className="up-form confirm-phone">
                  <p>
                    A one-time verification code will be sent to this mobile
                    number
                  </p>
                  <p>
                    Press ‘Confirm’ to proceed or ‘Edit’ to amend your details.
                  </p>
                  {this.state.regcust_error != "" && (
                    <p className="error_info">{this.state.regcust_error}</p>
                  )}
                  <div className="up-frm-btn">
                    <a
                      href={void 0}
                      className="button ghost-btn"
                      onClick={this.goBackTo.bind(this, "regphone")}
                    >
                      Edit
                    </a>
                    <a
                      href={void 0}
                      className="button cust-reg-btn"
                      onClick={this.confirmPhone.bind(this)}
                    >
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            )}

            {this.state.bottompopup == "regotp" && (
              <div className="enter-otp">
                <h2>Enter OTP</h2>
                <div className="four-bx-col">
                  <div className="four-bx">
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt1"
                      id="reg_otp_dgt1"
                      value={this.state.regotpdata.reg_otp_dgt1}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt2"
                      id="reg_otp_dgt2"
                      value={this.state.regotpdata.reg_otp_dgt2}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt3"
                      id="reg_otp_dgt3"
                      value={this.state.regotpdata.reg_otp_dgt3}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt4"
                      id="reg_otp_dgt4"
                      value={this.state.regotpdata.reg_otp_dgt4}
                      onChange={this.handleFldChange.bind(this)}
                    />
                  </div>
                  {this.displayRunNumbar()}
                </div>
                {this.state.trigger_otp != "Yes" && (
                  <div className="otp-checkbox">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          name="regotp_terms"
                          onChange={this.changeRegOtpChkBox.bind(this, "trams")}
                          checked={this.chakRegOtpChkBox("trams")}
                        />
                        <p>
                          I confirm I have read and accept the{" "}
                          <a href={void 0}>Terms & Conditions</a>,{" "}
                          <a href={void 0}>Privacy Policy</a> and the{" "}
                          <a href={void 0}>PDPA consent</a>.
                        </p>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="regotp_promo"
                          onChange={this.changeRegOtpChkBox.bind(this, "promo")}
                          checked={this.chakRegOtpChkBox("promo")}
                        />
                        <p>
                          I'd like to receive news, updates and promotions from
                          Buzzr via email and sms
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
                {this.state.trigger_otp === "Yes" ? (
                  <div className="up-frm-btn">
                    <a
                      href={void 0}
                      className="button ghost-btn"
                      onClick={this.cancelAct.bind(this)}
                    >
                      Cancel
                    </a>
                    <a
                      href={void 0}
                      className="button cust-reg-btn"
                      onClick={this.tgrVerifyOtp.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                ) : (
                  <div className="bottom-btn textcenter">
                    <a
                      href={void 0}
                      className="button full-btn reg-otp-verify"
                      onClick={this.regVerifyOtp.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                )}
              </div>
            )}

            {this.state.bottompopup == "regpersonal" && (
              <div className="update-personal">
                <div className="up-header textcenter">
                  <p>Update your personal details</p>
                </div>
                <div className="up-form">
                  <input
                    type="text"
                    placeholder="Name"
                    name="cust_name"
                    value={this.state.cust_name}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="cust_email_id"
                    value={this.state.cust_email_id}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <select
                    name="birthday_month"
                    value={this.state.birthday_month}
                    className="components_selct components_selctbox_cls"
                    onChange={this.handleFldChange.bind(this)}
                  >
                    <option value="">Birthday Month</option>
                    <option value="january">January</option>
                    <option value="february">February</option>
                    <option value="march">March</option>
                    <option value="april">April</option>
                    <option value="may">May</option>
                    <option value="june">June</option>
                    <option value="july">July</option>
                    <option value="august">August</option>
                    <option value="september">September</option>
                    <option value="october">October</option>
                    <option value="november">November</option>
                    <option value="december">December</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Referral Code"
                    name="cust_ref_code"
                    value={this.state.cust_ref_code}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  {this.state.regcust_error != "" && (
                    <p className="error_info">{this.state.regcust_error}</p>
                  )}
                  <div className="up-frm-btn">
                    <a
                      href={void 0}
                      className="button ghost-btn"
                      onClick={this.goBackTo.bind(this, "regphone")}
                    >
                      Cancel
                    </a>
                    <a
                      href={void 0}
                      className="button cust-reg-btn"
                      onClick={this.custRegistration.bind(this)}
                    >
                      Register
                    </a>
                  </div>
                </div>
              </div>
            )}

            {this.state.bottompopup == "loginfrm" && (
              <div className="welcome-phone login-frm">
                <div className="wp-top">
                  <h2>Login to EpicPay</h2>
                  <p>Enter your phone number to and login to your account</p>
                </div>
                <div className="input-phone">
                  <div className="prefix-merge">
                    <div className="prefix">+65</div>
                    <div className="nxt-fix">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_phone_no"
                        value={this.state.reg_phone_no}
                        placeholder="Phone number"
                        onChange={this.handleFldChange.bind(this)}
                      />
                    </div>
                  </div>
                  {this.state.regphone_error != "" && (
                    <span className="error-info">
                      {this.state.regphone_error}
                    </span>
                  )}
                  <p>
                    We will send you a one-time SMS, Additional carriers charges
                    may apply
                  </p>
                </div>
                <div className="bottom-btn bb-txt textcenter">
                  <a
                    href={void 0}
                    className="button full-btn login-otp-link"
                    onClick={this.regSendOtp.bind(this, "login")}
                  >
                    Login
                  </a>
                  <p>
                    Do not have an account ?{" "}
                    <a
                      href={void 0}
                      onClick={this.goBackTo.bind(this, "regphone")}
                    >
                      Register here
                    </a>
                  </p>
                </div>
              </div>
            )}

            {this.state.bottompopup == "loginotp" && (
              <div className="enter-otp enter-otp-login">
                <h2>Enter OTP</h2>
                <div className="four-bx-col">
                  <div className="four-bx">
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt1"
                      id="reg_otp_dgt1"
                      value={this.state.regotpdata.reg_otp_dgt1}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt2"
                      id="reg_otp_dgt2"
                      value={this.state.regotpdata.reg_otp_dgt2}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt3"
                      id="reg_otp_dgt3"
                      value={this.state.regotpdata.reg_otp_dgt3}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt4"
                      id="reg_otp_dgt4"
                      value={this.state.regotpdata.reg_otp_dgt4}
                      onChange={this.handleFldChange.bind(this)}
                    />
                  </div>
                  {this.displayRunNumbar()}
                </div>
                <div className="bottom-btn">
                  <a
                    href={void 0}
                    className="button full-btn"
                    onClick={this.loginVerifyOtp.bind(this)}
                  >
                    Login
                  </a>
                </div>
              </div>
            )}
          </div>
        </BottomSheet>
        </div>
      );
    } else {
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div class="lds-hourglass"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  return {
    customerdetails: customerdetailsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(ThankyouDirectPay));
