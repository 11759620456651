import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import axios from "axios";
import Header from "../Layout/Header";
import cookie from "react-cookies";
import { Instagram } from "react-content-loader";
import { GET_CUSTOMER_DETAILS, GET_PRODUCT_LIST } from "../../actions";
import { baseUrl, unquieID, apiUrl, headerconfig } from "../Settings/Config";

import desi from "../../common/images/desigual.svg";
import globe from "../../common/images/globe.svg";
import calla from "../../common/images/phone-call.svg";
import walletlight from "../../common/images/voucher.svg";
import plus from "../../common/images/plus-sign.svg";
import minus from "../../common/images/minus-sign.svg";
import voucherbg from "../../common/images/voucher-bg.png";
import Swal from "sweetalert2";
import {
  showPriceValue,
  stripslashes,
  decodeValue,
  encodeValue,
} from "../Helpers/SettingHelper";

var Parser = require("html-react-parser");
var qs = require("qs");
class BuyVoucherDetail extends Component {
  constructor(props) {
    super(props);
    var influencerID = "";
    var influencertoken = "";
    var analyticsType = "";
    if (
      this.props.match.path === "/buy-vouchers/:slugValue/share/:analyticsType"
    ) {
      analyticsType = this.props.match.params?.analyticsType || "";
    } else {
      influencerID = this.props.match.params?.influencerID || "";
      influencertoken = this.props.match.params?.token || "";
    }

    let customerId = cookie.load("UserId") || "";
    let voucherSlug = this.props.match?.params?.slugValue || "";
    console.log(this.props, voucherSlug, "this.props");
    this.state = {
      current_page: "Voucher Details",
      backLink: "buy-vouchers",
      customerData: [],
      productList: [],
      quantity: 1,
      loading: true,
      influencerID: influencerID,
      customerId: customerId,
      voucherSlug: voucherSlug,
      influencertoken: influencertoken,
      analyticsType: analyticsType,
      unquieID: unquieID,
      currentTab: 1,
    };

    if (
      customerId === "" &&
      influencerID === "" &&
      influencertoken === "" &&
      analyticsType === ""
    ) {
      props.history.push("/");
    }

    if (
      unquieID === "" &&
      influencerID === "" &&
      influencertoken === "" &&
      analyticsType === ""
    ) {
      props.history.push("/home");
    }
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");

    if (this.state.influencertoken !== "" && this.state.influencerID !== "") {
    } else if (this.state.influencerID !== "") {
      localStorage.setItem("influencerID", this.state.influencerID);
    }
    if (this.state.customerId !== "") {
      this.props.getCustomerDetails("&customer_id=" + this.state.customerId);
    }

    if (this.state.voucherSlug !== "") {
      var influencer =
        this.state.influencerID !== ""
          ? "&influencerID=" + this.state.influencerID
          : "";
      var analytics_Type =
        this.state.analyticsType !== ""
          ? "&analyticsType=" + this.state.analyticsType
          : "";
      this.props.getProductList(
        "product_type=7&product_slug=" +
          this.state.voucherSlug +
          influencer +
          analytics_Type +
          "&customerId=" +
          this.state.customerId
      );
    } else {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails });
    }
    if (this.state.productList !== PropsDt.productlist) {
      this.setState(
        { productList: PropsDt.productlist, loading: false },
        () => {
          var product_ = this.state.productList;
          this.setState({currentTab:product_[0].product_long_description !== "" && product_[0].product_long_description !==null?4:1})
          if (PropsDt.productlist.length > 0) {
            if (
              this.state.influencertoken !== "" &&
              this.state.influencerID !== ""
            ) {
              this.logininfluencer();
            }

            if (
              this.state.influencerID !== "" &&
              this.state.influencertoken === ""
            ) {
              this.addClick();
            }
            if (this.state.analyticsType !== "") {
              this.analyticsClick();
            }
          }
        }
      );
    }
  }
  addClick() {
    var postObject = {
      influencerID: this.state.influencerID,
      productID: encodeValue(this.state.productList[0].product_primary_id),
      customerID: this.state.customerId,
    };
    axios.post(apiUrl + "customer/brandClicked", qs.stringify(postObject));
  }
  analyticsClick() {
    var postObject = {
      analyticsType: this.state.analyticsType,
      productID: encodeValue(this.state.productList[0].product_primary_id),
      UniqueID: this.state.productList[0].product_company_unique_id,
      customerID: this.state.customerId,
    };
    localStorage.setItem(
      "shareProductID",
      encodeValue(this.state.productList[0].product_primary_id)
    );
    localStorage.setItem("analyticsType", this.state.analyticsType);

    axios.post(apiUrl + "products/analyticsClick", qs.stringify(postObject));
  }
  logininfluencer() {
    var postObject = {
      influencerID: this.state.influencerID,
    };

    axios
      .post(apiUrl + "customer/influencerLogin", qs.stringify(postObject), {
        headers: { Authorization: this.state.influencertoken },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          let custArr = res.data.result;
          localStorage.setItem(
            "company_app_id",
            this.state.productList[0].product_company_unique_id
          );
          localStorage.setItem(
            "influencerBuyNow",
            this.state.influencertoken !== "" ? "Yes" : "No"
          );

          cookie.save("UserId", custArr.customer_id, { path: "/" });

          this.setState({ customerId: custArr.customer_id });
          cookie.save("UserEmail", custArr.customer_email, { path: "/" });
          cookie.save(
            "UserFname",
            custArr.customer_first_name !== ""
              ? custArr.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            custArr.customer_last_name !== "" ? custArr.customer_last_name : "",
            { path: "/" }
          );
          cookie.save("UserMobile", decodeValue(custArr.customer_phone), {
            path: "/",
          });
          cookie.save("IsVerifiedUser", "No", { path: "/" });
          localStorage.setItem("token", custArr.token);
          let $_this = this;
          setTimeout(function () {
            window.location.href =
              baseUrl + "buy-vouchers/" + $_this.state.voucherSlug;
          }, 500);
        } else {
          this.setState({ regphone_error: res.data.message });
        }
      });
  }

  QtyAction(actionFlg) {
    let quantity = this.state.quantity;
    quantity = parseInt(quantity);
    if (actionFlg === "decr") {
      quantity = quantity > 0 ? quantity - 1 : quantity;
      if (quantity === 0) {
        quantity = 1;
      }
    } else {
      quantity = quantity + 1;
    }
    console.log(this.state.productList[0].productWelcomeVoucher, 'this.state.productList[0].productWelcomeVoucher')
    if(this.state.productList[0].productWelcomeVoucher!=="1") {
      this.setState({ quantity: quantity });
    }

    
  }
  buyVoucher() {
    if (this.state.customerId !== "") {
      let productDetails = this.state.productList[0];
      if (productDetails.productFree === "1" || productDetails.productWelcomeVoucher==="1") {
        if(productDetails.alreadyredeem==="no") {
          this.placeOrderBuyVoucher();
        }else {
          Swal.fire({
            title: "Error",
            html: 'Already issued',
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
        }
      } else {
        this.props.history.push({
          pathname: "/checkout",
          state: {
            voucherSlug: this.state.voucherSlug,
            quantity: this.state.quantity,
            checkoutType: "buyvoucher",
          },
        });
      }
    } else {
      if (cookie.load("IsVerifiedUser") !== "Yes") {
        cookie.save("triggerFrom", "checkout", { path: "/" });
        localStorage.setItem(
          "company_app_id",
          this.state.productList[0].product_company_unique_id
        );
        var details = {
          voucherSlug: this.state.voucherSlug,
          quantity: this.state.quantity,
          checkoutType: "buyvoucher",
          triggerFrom: "checkout",
        };

        setTimeout(() => {
          this.props.history.push({
            pathname: "/",
            state: details,
          });
        }, 100);
      }
    }
  }

  placeOrderBuyVoucher() {
    let productList = this.state.productList[0];
    let customer_id = this.state.customerId;
    let productID = encodeValue(productList.product_id);
    let plan_qty = this.state.quantity;
    let subtotal_amount = 0;
    let total_amount = 0;
    let tax_percentage = "";
    let terms_conditions = "";
    let promotions_updates = "";

    var postObject = {
      app_id: productList.product_company_unique_id,
      customer_id: customer_id,
      productID: productID,
      plan_qty: plan_qty,
      subtotal_amount: subtotal_amount,
      total_amount: total_amount,
      tax_percentage: tax_percentage,
      terms_conditions: terms_conditions,
      promotions_updates: promotions_updates,
      qrcode: "",
      checkoutType: "buyvoucher",
      influencerID: this.state.influencerID,
      influencerBuyNow: localStorage.getItem("influencerBuyNow") || "",
      shareProductID: localStorage.getItem("shareProductID") || "",
      analyticsType: localStorage.getItem("analyticsType") || "",
    };

    axios
      .post(
        apiUrl + "wallettopup/topup",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var voucherType = localStorage.getItem("voucherType");
          localStorage.removeItem("influencerBuyNow");
          localStorage.removeItem("planData");
          localStorage.removeItem("voucherSlug");
          localStorage.removeItem("voucherquantity");
          localStorage.removeItem("influencerID");
          localStorage.removeItem("voucherType");
          localStorage.removeItem("paymentRequestId");
          localStorage.removeItem("shareProductID");
          localStorage.removeItem("analyticsType");

          var msg = "Buy Voucher successful";
          if (voucherType === "C") {
            msg = "Topup successful";
          } else if (voucherType === "F") {
            msg = "Buy Voucher successful";
          }
          Swal.fire({
            title: "Success",
            html: msg,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          if (voucherType === "C") {
            this.props.history.push("/myaccount");
          } else {
            this.props.history.push("/vouchers/available");
          }
        } else {
          Swal.fire({
            title: "Error",
            html: res.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
        }
      });
  }
  selectTab(currentTab, e) {
    e.preventDefault();
    console.log(currentTab, "currentTabcurrentTabcurrentTab");
    this.setState({ currentTab });
  }

  render() {
    var product = this.state.productList;
    return (
      <div className="main-div voucher-details">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />
        {this.state.loading === false && product.length > 0 ? (
          <div className="rel">
            <div className="container">
              <div className="termsandcond-info">
                <br></br>
                <div className="spread">
                  <div className="abstract">
                    <div className="cover-image">
                      <img
                        src={product[0].image !== "" ? product[0].image : desi}
                        alt="banner"
                      />
                    </div>
                    <div className="abstract-desc">
                      <div className="abd-title">
                        <h3>{product[0].company_name}</h3>
                        {/*  <a href={() => false} className="button ghost-button">
                          Available Locations
                        </a> */}
                      </div>
                      {product[0].informations !== "" &&
                      product[0].informations !== null
                        ? Parser(product[0].informations)
                        : ""}

                      <ul>
                        {product[0].outlet_website !== "" &&
                          product[0].outlet_website !== null && (
                            <li className="website-link">
                              {" "}
                              <a
                                href={product[0].outlet_website}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={globe} alt="website" />{" "}
                                <span>{product[0].outlet_website}</span>
                              </a>{" "}
                            </li>
                          )}
                        {product[0].outlet_phone !== "" &&
                          product[0].outlet_phone !== null && (
                            <li>
                              {" "}
                              <a href={"tel:65" + product[0].outlet_phone}>
                                <img src={calla} alt="phone" /> +65{" "}
                                {product[0].outlet_phone}
                              </a>{" "}
                            </li>
                          )}
                      </ul>
                    </div>
                  </div>
                  <div className="summary">
                    {this.state.influencerID !== "" && (
                      <div className="blk-summary textcenter">
                        This Deal Is Referred By <strong>Mr.Beast</strong>
                      </div>
                    )}
                    <div className="orange-banner">
                      <img src={voucherbg} alt="voucherbg" />
                    </div>
                    <div className="credit-list-full">
                      <ul className="list-ul">
                        <li className="list-li">
                          <div className="list-parent-one">
                            <figure>
                              {" "}
                              <img src={walletlight} alt="walletlight" />{" "}
                            </figure>
                            <div className="buy-credit-txt-ful">
                              <h2>
                                {product[0].product_alias !== ""
                                  ? stripslashes(product[0].product_alias)
                                  : stripslashes(product[0].product_name)}
                              </h2>
                              
                              {product[0].product_short_description !== "" && product[0].product_short_description !==null ? (
                                 <span>
                                 {Parser(product[0].product_short_description)}
                               </span>
                              ):(product[0].product_long_description !== "" && product[0].product_long_description !==null ? (
                                <span>
                                  {Parser(product[0].product_long_description)}
                                </span>
                              ) : (
                                ""
                              ))}
                              {product[0].giftProduct!==null &&
                              <strong>+FREE {(product[0].giftProduct.product_alias!==null && product[0].giftProduct.product_alias!=="")?product[0].giftProduct.product_alias:product[0].giftProduct.product_name}</strong>
                              }
                            </div>
                            <div className="credit-offer-big">
                              {parseFloat(product[0].product_price)>0 ?(
                               <>
                               <span>Buy For </span>
                             <h2>
                               {product[0].product_price !== ""
                                 ? showPriceValue(product[0].product_price, 0)
                                 : ""}
                             </h2>
                             </>                             
                              ):(
                                
                                <h2>Free </h2>
                              )}
                              
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="fav-tab">
                      <div className="fav-tab-nav">
                        <ul>
                        {product[0].product_long_description !== "" && product[0].product_long_description !==null &&
                        <li
                            className={
                              this.state.currentTab === 4 ? "active" : ""
                            }
                          >
                            <a href="/" onClick={this.selectTab.bind(this, 4)}>
                              Description
                            </a>
                          </li>
                          }
                          <li
                            className={
                              this.state.currentTab === 1 ? "active" : ""
                            }
                          >
                            <a href="/" onClick={this.selectTab.bind(this, 1)}>
                              How To Redeem
                            </a>
                          </li>
                          {product[0].product_termsandconditions !== "" && (
                            <li
                              className={
                                this.state.currentTab === 2 ? "active" : ""
                              }
                            >
                              <a
                                href="/"
                                onClick={this.selectTab.bind(this, 2)}
                              >
                                Terms & Conditions
                              </a>
                            </li>
                          )}
                          <li
                            className={
                              this.state.currentTab === 3 ? "active" : ""
                            }
                          >
                            <a href="/" onClick={this.selectTab.bind(this, 3)}>
                              Available Location
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="fav-tab-content">
                      {this.state.currentTab === 4 && (
                          <div className="hoe-to">
                            {Parser(product[0].product_long_description)}
                          </div>
                      )}
                        {this.state.currentTab === 1 && (
                          <div className="hoe-to">
                            <h4>How to redeem your voucher</h4>
                            {product[0].product_voucher === "C" ? (
                              <ol>
                                <li>
                                  <span>Visit a participating location</span>
                                </li>
                                <li>
                                  <span>Click to redeem your voucher</span>
                                </li>
                                <li>
                                  <span>
                                    Show QR to counter to redeem your purchase
                                  </span>
                                </li>
                              </ol>
                            ) : (
                              <ol>
                                <li>
                                  <span>Visit a participating location</span>
                                </li>
                                <li>
                                  <span>
                                    Open this email and click the button below
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Follow the insturctions on the redemption
                                    page
                                  </span>
                                </li>
                              </ol>
                            )}
                          </div>
                        )}
                        {this.state.currentTab === 2 &&
                          product[0].product_termsandconditions !== "" &&
                          product[0].product_termsandconditions !== null && (
                            <div className="hoe-to">
                              {Parser(product[0].product_termsandconditions)}
                            </div>
                          )}
                        {this.state.currentTab === 3 && (
                          <div className="hoe-to location-list-prtn">
                            {product[0].selectedOutlet.length > 0 && (
                              <ul className="location-list">
                                {product[0].selectedOutlet.map(
                                  (item, index) => {
                                    var outDetail =
                                      product[0].outletList[item] || "";
                                    if (outDetail !== "") {
                                      return (
                                        <li key={index}>
                                          <div>
                                            <h3>{outDetail.outlet_name}</h3>
                                            <p>
                                              <i
                                                class="fa fa-map-marker"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              {outDetail.outlet_unit_number1 !==
                                                "" ||
                                              outDetail.outlet_unit_number2 !==
                                                ""
                                                ? "#"
                                                : ""}
                                              {outDetail.outlet_unit_number1}
                                              {outDetail.outlet_unit_number1 !==
                                              ""
                                                ? "-"
                                                : ""}
                                              {outDetail.outlet_unit_number2}{" "}
                                              {outDetail.outlet_address_line1} -{" "}
                                              {outDetail.outlet_postal_code}{" "}
                                              Singapore
                                            </p>
                                            {outDetail.outlet_email !== "" && (
                                              <p>
                                                <i
                                                  class="fa fa-envelope-o"
                                                  aria-hidden="true"
                                                ></i>
                                                {outDetail.outlet_email}
                                              </p>
                                            )}
                                            {outDetail.outlet_phone !== "" && (
                                              <p>
                                                <i
                                                  class="fa fa-phone"
                                                  aria-hidden="true"
                                                ></i>
                                                {outDetail.outlet_phone}
                                              </p>
                                            )}
                                          </div>
                                        </li>
                                      );
                                    }else {
                                      return "";
                                    }
                                  }
                                )}
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="voucher-buy-now">
                      <div className="qty-bx">
                        <span
                          className="qty-minus"
                          onClick={this.QtyAction.bind(this, "decr")}
                        >
                          <img src={minus} alt="minus" />
                        </span>
                        <div className="input-quantity">
                          {this.state.quantity}
                        </div>
                        <span
                          className="qty-plus"
                          onClick={this.QtyAction.bind(this, "incr")}
                        >
                          <img src={plus} alt="plus" />
                        </span>
                      </div>
                      <a
                        href={() => false}
                        onClick={this.buyVoucher.bind(this)}
                        className="button orng-btn"
                      >
                        {product[0].productWelcomeVoucher==="1" ?'Get Free Voucher':'Buy Now'}
                        
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="rel">
            <div className="container">
              <Instagram
                backgroundColor={"#c7c7c7"}
                foregroundColor={"#c7c7c7"}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  var productlistArr = [];
  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0].result_set;
    }
  }
  return {
    customerdetails: customerdetailsArr,
    productlist: productlistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCT_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(BuyVoucherDetail));
