import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import cookie from "react-cookies";
import update from "immutability-helper";
import axios from "axios";

import {
  GET_REWARDSETTING_LIST,
} from "../../actions";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { apiUrl, unquieID, baseUrl } from "../Settings/Config";
import {
  showPriceSeparate,
  showLoaderLst,
  hideLoaderLst,
  showPriceValue
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import menuicon from "../../common/images/menu-icon.svg";
import paybillicon from "../../common/images/paybill-icon.svg";
import offerimage from "../../common/images/offerimage.png";
import cellphone1 from "../../common/images/cellphone1.svg";
import selectedVoucher from "../../common/images/selectedVoucher.png";
import blueround from "../../common/images/blueround.png";
import startop from "../../common/images/star-top.png";
import starbottom from "../../common/images/star-bottom.png";
var qs = require("qs");
class DirectPayHome extends Component {
  constructor(props) {
    super(props);
    const loginType = cookie.load("loginType") || "";

    var refcode =
      localStorage.getItem("refcode") === null
        ? ""
        : localStorage.getItem("refcode");
    var triggerOTP = "No";
    var triggerFrom = "";
    var bottompopup = "regphone";
    var reg_phone_no = "";
    if (cookie.load("triggerOTP") == "Yes") {
      triggerOTP = "Yes";
      triggerFrom = cookie.load("triggerFrom");
      reg_phone_no = cookie.load("UserMobile");
      bottompopup = "regotp";
    }

    this.state = {
      current_page: "guesthome",
      loginType: loginType,
      designType: "new",
      withheaderbg: "yes",
      hideBack: "yes",
      allowToShowCompany: "yes",
      gSettings: "",
      typeofCreditRedeem: 1,
      menu_url:"",
      voucherList: [],

      runingNum: 0,
      bottompopup: bottompopup,
      trigger_otp: triggerOTP,
      trigger_from: triggerFrom,
      reg_phone_no: reg_phone_no,
      regphone_error: "",
      regotpdata: {
        reg_otp_dgt1: "",
        reg_otp_dgt2: "",
        reg_otp_dgt3: "",
        reg_otp_dgt4: "",
      },
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      regcust_error: "",
      openLogin: false,
      welcomeVoucher: [],
      selectedVoucher: "",
      rewardsettingsdata: [],
      
    };
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSettings();
    this.getProductData();
    this.loadWelcomeVoucher();
    this.props.getRewardSettingList("&customer_id=");
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.rewardsettingsdata !== PropsDt.rewardsettingslist) {
      this.setState({ rewardsettingsdata: PropsDt.rewardsettingslist });
    }
  }
  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?unquieID=" + unquieID;
    axios
      .get(urlShringTxt, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          this.setState({
            gSettings: result,
            clientLogo: result?.company_logo,
            companyName: result?.company_name,
            typeofCreditRedeem: result?.typeofCreditRedeem || 1,
            menu_url:result?.company_menu_url || ''
          });
        }
      });
  }
  loadWelcomeVoucher() {
    var customerID = cookie.load("UserId") || "";
    var urlShringTxt =
      apiUrl +
      "products/products_list/?app_id=" +
      unquieID +
      "&welcomevoucher=1&customerId=" +
      customerID;
    axios
      .get(urlShringTxt, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            welcomeVoucher: res.data.result_set,
          });
        }
      });
  }

  getProductData() {
    axios
      .get(
        `${apiUrl}products/products_list?app_id=${unquieID}&product_type=7&recomentedVoucher=Yes`,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ voucherList: res.data.result_set });
        }
      });
  }
  gotoVoucherDetails(selectedVoucher) {
    this.props.history.push({
      pathname: "/directpay/checkout",
      state: { selectedVoucher: selectedVoucher },
    });
  }

  gotoWelcomeVoucher(selectedVoucher) {
    if (selectedVoucher.alreadyredeem === "no") {
      if (this.state.loginType === "Guest") {
        this.setState({ selectedVoucher: selectedVoucher, openLogin: true });
      } else {
        this.props.history.push(
          "/buy-vouchers/" + selectedVoucher.product_slug
        );
      }
    }
  }

  handleFldChange(event) {
    const re = /^[0-9 \b]+$/;
    if (event.target.name === "reg_phone_no") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumber = this.space(event.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 9) {
          this.setState({ reg_phone_no: mblnumber });
        }
      }
    } else if (
      event.target.name === "reg_otp_dgt1" ||
      event.target.name === "reg_otp_dgt2" ||
      event.target.name === "reg_otp_dgt3" ||
      event.target.name === "reg_otp_dgt4"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        var mblnumber = this.space(event.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 1) {
          let field = event.target.name;
          let fieldvalue = event.target.value;
          this.setState(
            update(this.state, {
              regotpdata: { [field]: { $set: fieldvalue } },
            }),
            function () {
              if (field === "reg_otp_dgt1" && mblnumberLenght == 1) {
                $("#reg_otp_dgt2").focus();
              }
              if (field === "reg_otp_dgt2" && mblnumberLenght == 1) {
                $("#reg_otp_dgt3").focus();
              }
              if (field === "reg_otp_dgt3" && mblnumberLenght == 1) {
                $("#reg_otp_dgt4").focus();
              }
            }
          );
        }
      }
    } else if (event.target.name === "cust_name") {
      this.setState({ cust_name: event.target.value });
    } else if (event.target.name === "cust_email_id") {
      this.setState({ cust_email_id: event.target.value });
    } else if (event.target.name === "birthday_month") {
      this.setState({ birthday_month: event.target.value });
    } else if (event.target.name === "cust_ref_code") {
      this.setState({ cust_ref_code: event.target.value });
    }
    this.setState({ regphone_error: "", regotp_error: "", regcust_error: "" });
  }

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    var downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }

  triggerOTPFunction() {
    var regphoneNo = cookie.load("UserMobile");
    if (
      regphoneNo != "" &&
      typeof regphoneNo !== undefined &&
      typeof regphoneNo !== "undefined"
    ) {
      console.log(regphoneNo, "regphoneNo");
      regphoneNo = regphoneNo.replace(/ /g, "");
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
      };
      showLoaderLst("home-page-maindiv", "class");
      axios
        .post(apiUrl + "customer/send_otponly", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("home-page-maindiv", "class");
          if (res.data.status === "ok") {
            this.setState(
              { bottompopup: "regotp", runingNum: 120 },
              function () {
                this.setRuningNum();
              }.bind(this)
            );
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  regSendOtp(typeflg, event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    if (regphoneNo != "") {
      var postObject = {
        customer_mobile: regphoneNo,
        otp_type: typeflg,
      };
      if (unquieID !== "") {
        postObject["app_id"] = unquieID;
      }
      let loadrdivcls = typeflg == "login" ? "login-otp-link" : "reg-otp-link";
      showLoaderLst(loadrdivcls, "class");
      axios
        .post(apiUrl + "customer/send_customer_otp", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst(loadrdivcls, "class");
          if (res.data.status === "ok") {
            if (
              typeflg == "login" ||
              (typeflg == "register" && res.data.is_existing_user == "yes")
            ) {
              let custArr = res.data.result;
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("UserMobile", regphoneNo, { path: "/" });
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
              cookie.remove("loginType", { path: "/" });
              if (unquieID !== "") {
                localStorage.setItem("allowCompanyRedirect", "Yes");
              }
              let $_this = this;
              if (this.props.location?.state) {
                localStorage.setItem(
                  "voucherSlug",
                  $_this.props.location.state.voucherSlug
                );
                localStorage.setItem(
                  "voucherquantity",
                  $_this.props.location.state.quantity
                );
                setTimeout(function () {
                  if ($_this.state.selectedVoucher !== "") {
                    window.location.href =
                      baseUrl +
                      "buy-vouchers/" +
                      $_this.state.selectedVoucher.product_slug;
                  } else {
                    window.location.href = baseUrl + "directpay";
                  }
                }, 500);
              } else {
                setTimeout(function () {
                  if ($_this.state.selectedVoucher !== "") {
                    window.location.href =
                      baseUrl +
                      "buy-vouchers/" +
                      $_this.state.selectedVoucher.product_slug;
                  } else {
                    window.location.href = baseUrl + "directpay";
                  }

                  //$_this.props.history.push("/myaccount");
                }, 100);
              }
            } else {
              /*var bottompopuptxt = (typeflg == 'login')? 'loginotp' : 'regotp';*/
              this.setState({ bottompopup: "confirm" });
            }
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        });
    } else {
      this.setState({ regphone_error: "Phone number required" });
    }
  }

  confirmPhone(event) {
    event.preventDefault();
    this.setState(
      { bottompopup: "regotp", runingNum: 120 },
      function () {
        this.setRuningNum();
      }.bind(this)
    );
  }

  displayRunNumbar() {
    if (this.state.regotp_error != "") {
      return <p className="error_info">{this.state.regotp_error}</p>;
    } else if (this.state.runingNum > 0) {
      return (
        <p className="runing_num">Resend in {this.state.runingNum} Seconds</p>
      );
    } else {
      return <p className="resend_link">Resend</p>;
    }
  }

  chakRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field == "trams") {
      var chkBox = regotp_terms == "Yes" ? true : false;
      return chkBox;
    } else if (field == "promo") {
      var chkBox = regotp_promo == "Yes" ? true : false;
      return chkBox;
    }
  }

  changeRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field == "trams") {
      var regotpterms = regotp_terms == "Yes" ? "No" : "Yes";
      this.setState({ regotp_terms: regotpterms });
      if (regotp_terms == "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }

    if (field == "promo") {
      var regotpPromo = regotp_promo == "Yes" ? "No" : "Yes";
      this.setState({ regotp_promo: regotpPromo });
      if (regotpPromo == "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }
  }

  cancelAct(event) {
    event.preventDefault();
    var triggerfrom = this.state.trigger_from;
    triggerfrom =
      triggerfrom != "" && triggerfrom != undefined ? triggerfrom : "myaccount";
    if (triggerfrom == "checkout") {
      triggerfrom = "myaccount";
    }
    this.props.history.push("/" + triggerfrom);
  }

  tgrVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    console.log(regphoneNo, "regphoneNo");
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "redeempoints",
        influencerID: localStorage.getItem("influencerID"),
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            var triggerfrom = this.state.trigger_from;
            triggerfrom =
              triggerfrom != "" && triggerfrom != undefined
                ? triggerfrom
                : "myaccount";
            let $_this = this;
            console.log(this.props, "this.props");
            if (this.props.location?.state) {
              setTimeout(function () {
                if ($_this.state.selectedVoucher !== "") {
                  window.location.href =
                    baseUrl +
                    "buy-vouchers/" +
                    $_this.state.selectedVoucher.product_slug;
                } else {
                  $_this.props.history.push({
                    pathname: $_this.props.location.state.triggerFrom,
                    state: $_this.props.location.state,
                  });
                }
              }, 500);
            } else {
              setTimeout(function () {
                if ($_this.state.selectedVoucher !== "") {
                  window.location.href =
                    baseUrl +
                    "buy-vouchers/" +
                    $_this.state.selectedVoucher.product_slug;
                } else {
                  $_this.props.history.push("/" + triggerfrom);
                }
              }, 500);
            }
            return false;
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  regVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    } else if (this.state.regotp_terms != "Yes") {
      errorMgs = "Terms & Conditions required";
    } else if (this.state.regotp_promo != "Yes") {
      errorMgs = "Promotions required";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "register",
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            this.setState({ bottompopup: "regpersonal", runingNum: 0 });
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  goBackTo(refPage, event) {
    event.preventDefault();
    var regotpdata = {
      reg_otp_dgt1: "",
      reg_otp_dgt2: "",
      reg_otp_dgt3: "",
      reg_otp_dgt4: "",
    };
    this.setState({
      bottompopup: refPage,
      reg_phone_no: "",
      regphone_error: "",
      regotpdata: regotpdata,
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      cust_ref_code: "",
      regcust_error: "",
    });
  }

  custRegistration(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var errorMgs = "";
    if (regphoneNo == "") {
      errorMgs = "Mobile No required";
    } else if (this.state.cust_name == "") {
      errorMgs = "Name required";
    } else if (this.state.cust_email_id == "") {
      errorMgs = "Email required";
    } else if (this.state.birthday_month == "") {
      errorMgs = "Birthday month required";
    }

    if (errorMgs == "") {
      var shareProductID =
        cookie.load("shareProductID") !== "" &&
        typeof cookie.load("shareProductID") !== undefined &&
        typeof cookie.load("shareProductID") !== "undefined"
          ? cookie.load("shareProductID")
          : "";
      var analyticsType =
        cookie.load("analyticsType") !== "" &&
        typeof cookie.load("analyticsType") !== undefined &&
        typeof cookie.load("analyticsType") !== "undefined"
          ? cookie.load("analyticsType")
          : "";
      var postObject = {
        app_id: unquieID,
        customer_phone: regphoneNo,
        customer_email: this.state.cust_email_id,
        customer_birthmonth: this.state.birthday_month,
        customer_ref_code: this.state.cust_ref_code,
        customer_name: this.state.cust_name,
        shareProductID: localStorage.getItem("shareProductID") || "",
        analyticsType: localStorage.getItem("analyticsType") || "",
      };
      showLoaderLst("cust-reg-btn", "class");
      axios
        .post(apiUrl + "customer/registration", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("cust-reg-btn", "class");
          if (res.data.status === "ok") {
            //this.setState({ bottompopup: 'regpersonal', runingNum: 0 });

            let custArr = res.data.result_set;
            cookie.save("UserId", custArr.customer_id, { path: "/" });
            cookie.save("UserEmail", custArr.customer_email, { path: "/" });
            cookie.save(
              "UserFname",
              custArr.customer_first_name !== ""
                ? custArr.customer_first_name
                : "",
              { path: "/" }
            );
            cookie.save(
              "UserLname",
              custArr.customer_last_name !== ""
                ? custArr.customer_last_name
                : "",
              { path: "/" }
            );
            cookie.save("UserMobile", regphoneNo, { path: "/" });
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            localStorage.setItem("token", custArr?.token);
            cookie.remove("loginType", { path: "/" });
            let $_this = this;
            setTimeout(function () {
              if ($_this.state.selectedVoucher !== "") {
                window.location.href =
                  baseUrl +
                  "buy-vouchers/" +
                  $_this.state.selectedVoucher.product_slug;
              } else {
                window.location.href = baseUrl + "directpay";
              }
            }, 100);
          } else {
            this.setState({ regcust_error: res.data.message });
          }
        });
    } else {
      this.setState({ regcust_error: errorMgs });
    }
  }

  loginVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length != 4) {
      errorMgs = "Please enter the OTP";
    }

    if (regphoneNo != "" && errorMgs == "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "login",
      };
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ runingNum: 0 });
            if (this.state.selectedVoucher !== "") {
              window.location.href =
                baseUrl +
                "buy-vouchers/" +
                this.state.selectedVoucher.product_slug;
            } else {
              this.props.history.push("myaccount");
            }
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }
  openLogin(e) {
    e.preventDefault();
    this.setState({ openLogin: true });
  }

  rewardProLst(membership_spent_amount) {
      if (this.state.rewardsettingsdata.length > 0) {
        const rewardsettingsHtml = this.state.rewardsettingsdata.map(
          (rewardsettings, rwInt) => {
            let msnCls = rewardsettings.is_earned === "Yes" ? "active" : "";
            let proImg =
              rewardsettings.reward_freeproduct_image !== "" ? (
                <img src={rewardsettings.reward_freeproduct_image} alt="Coin" />
              ) : (
                ""
              );
            return (
              <li key={rwInt} className={msnCls}>
                <figure>{proImg}</figure>
                <div className="reward-reach">
                  <em>{rewardsettings.reward_freeproduct_name}</em>
                  <span>
                    {showPriceValue(rewardsettings.reward_pointstoreach)}{" "}
                  </span>
                </div>
              </li>
            );
          }
        );
  
        var calcMemberPerc = 0;
        let indxVal = Object.keys(this.state.rewardsettingsdata).length - 1;
        let membership_max_amount = parseInt(
          this.state.rewardsettingsdata[indxVal].reward_pointstoreach
        );
  
        if (membership_max_amount) {
          if (
            parseFloat(membership_spent_amount) >
            parseFloat(membership_max_amount)
          ) {
            calcMemberPerc = 100;
          } else {
            calcMemberPerc =
              (membership_spent_amount / membership_max_amount) * 100;
          }
        }
  
        return (
          <>
            <div className="rewards-uget">
              <h2>Rewards You Get</h2>
              <span>Spend Your Credits To Unlock Exciting Vouchers</span>
              <ul>{rewardsettingsHtml}</ul>
            </div>
            <div className="ovr-view-inner">
              <div className="slide-frame">
                <div
                  className="inner-frame-bg"
                  style={{ width: calcMemberPerc + "%" }}
                ></div>
              </div>
              <div className="slide-frame1 active"></div>
              <div
                className={
                  parseFloat(calcMemberPerc) === 100
                    ? "slide-frame2 active"
                    : "slide-frame2"
                }
              ></div>
              {this.rewardActLst(membership_spent_amount)}
            </div>
          </>
        );
      }
    }

    rewardActLst(membership_spent_amount) {
        const rewardsettingsHtml = this.state.rewardsettingsdata.map(
          (rewardsettings, rwInt) => {
            let msnCls =
              parseFloat(membership_spent_amount) >=
              parseFloat(rewardsettings.reward_pointstoreach)
                ? "active"
                : "";
            return (
              <li key={rwInt} className={msnCls}>
                {showPriceValue(rewardsettings.reward_pointstoreach)}
              </li>
            );
          }
        );
    
        return (
          <ul>
            <li className="active">0</li>
            {rewardsettingsHtml}
          </ul>
        );
      }
  viewMenu(menu_url, e) {
    e.preventDefault();
    if(menu_url!=="") {
      window.open(menu_url, '_blank').focus();
    }
    
  }
  render() {
    return (
      <div className="directpay-new home-page">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />
        <div className="container">
          <div className="home-menu">
            {this.state.loginType === "Guest" && (
              <div className="login-section">
                <a
                  href="/"
                  onClick={this.openLogin.bind(this)}
                  className="login-link"
                >
                  If you already have an account,{" "}
                  <span>Click here to Login</span>
                </a>
              </div>
            )}

            <div className="button-container">
              <a href="#" onClick={this.viewMenu.bind(this, this.state.menu_url)} className="btn view-menu">
                <img src={menuicon} alt="" />
                <span>View Menu</span>
              </a>
              <Link to={`/directpay/checkout`} className="btn pay-bill">
                <img src={paybillicon} alt="" />
                <span>Pay Bill</span>
              </Link>
            </div>
            {this.state.welcomeVoucher.length > 0 &&
              this.state.welcomeVoucher.map((item) => {
                return (
                  <div
                    className="promotion-section"
                    onClick={this.gotoWelcomeVoucher.bind(this, item)}
                  >
                    <img
                      src={
                        item.product_thumbnail !== "" &&
                        item.product_thumbnail !== null
                          ? item.product_thumbnail
                          : offerimage
                      }
                      alt="Breakfast offer"
                      className="promo-image"
                    />
                    <div
                      className={`promo-text ${
                        item.alreadyredeem === "yes" ? "used" : ""
                      }`}
                    >
                      <p>
                        {item.alreadyredeem === "yes"
                          ? "Already issued"
                          : "Claim Your Free Welcome Voucher"}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          {this.state.voucherList.length > 0 && (
            <div className="vouchersList">
              <h1>Enjoy Big Bonus</h1>
              <h2>Use Cash Vouchers to Pay</h2>
              <div className="vouchers">
                {this.state.voucherList.map((item, index) => {
                  if(index<=2) {
                  return (
                    <div key={index}>
                    <div
                      className={`voucher${item.giftProduct!==null?' avail-free':''}${index===0?' selected':''}`}                      
                      onClick={this.gotoVoucherDetails.bind(this, index)}
                    >
                      <div>
                      {index===0?(
                  <>
                   <img src={selectedVoucher} className="tickimg" />
                   <img src={startop} className="startop" />
                   
                   </>
                ):(
                  <img src={blueround} className="tickimg" />
                )}     
                      <h2>
                        <span>{item.discountPrecentage}%</span>
                        <br />
                        {this.state.typeofCreditRedeem === "2" ? "Discount" : "Bonus"}
                      </h2>
                      </div>
                      <div className="pay-amount-parent">
                      <p>Pay</p>
                      <p className="pay-amount">
                        {showPriceSeparate(item.product_price, 0, "no")}
                      </p>
                      </div>
                      
                      <p className="plus">+</p>
                      
                      <div className="bonus">
                        <p>Bonus</p>
                        <p className="bonus-amount">
                          {this.state.typeofCreditRedeem === "2"
                            ? item.discountPrecentage + "%"
                            : showPriceSeparate(
                                item.product_voucher_bonus_amount,
                                0,
                                "no"
                              )}
                        </p>
                        {index === 0 &&(                 
                          <img src={starbottom} className="starbottom" />
                        )}
                      </div>             
                    </div>
                    {item.giftProduct!==null &&
                        <div className="free-vouchers">
                          + FREE {item.giftProduct.product_name}
                        </div>
                      }
                    </div>
                  );
                }
                })}
              </div>
            </div>
          )}

              <div className="upcoming-trans">
                {this.rewardProLst(0)}
              </div>

        </div>

        <BottomSheet
          open={this.state.openLogin}
          onDismiss={() => {
            this.setState({ openLogin: false });
          }}
        >
          <div className="container">
            {this.state.bottompopup == "regphone" && (
              <div className="welcome-phone">
                <div className="wp-top">
                  <h2>Welcome to EpicPay</h2>
                  <p>Enter your phone number to create your account</p>
                </div>
                <div className="input-phone">
                  <div className="prefix-merge">
                    <div className="prefix">+65</div>
                    <div className="nxt-fix">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_phone_no"
                        value={this.state.reg_phone_no}
                        placeholder="Phone number"
                        onChange={this.handleFldChange.bind(this)}
                      />
                    </div>
                  </div>
                  {this.state.regphone_error != "" && (
                    <span className="error-info">
                      {this.state.regphone_error}
                    </span>
                  )}
                  <p>
                    We will send you a one-time SMS, Additional carriers charges
                    may apply
                  </p>
                </div>
                <div className="bottom-btn bb-txt textcenter">
                  <a
                    href={void 0}
                    className="button full-btn reg-otp-link"
                    onClick={this.regSendOtp.bind(this, "register")}
                  >
                    Send OTP
                  </a>
                  <p>
                    Do you have an account ?{" "}
                    <a
                      href={void 0}
                      onClick={this.goBackTo.bind(this, "loginfrm")}
                    >
                      Login here
                    </a>
                  </p>
                </div>
              </div>
            )}

            {this.state.bottompopup == "confirm" && (
              <div className="update-personal">
                <div className="up-header confirm-header textcenter">
                  <h2>
                    You have entered<br></br>+65 {this.state.reg_phone_no}
                  </h2>
                  <img src={cellphone1} />
                </div>
                <div className="up-form confirm-phone">
                  <p>
                    A one-time verification code will be sent to this mobile
                    number
                  </p>
                  <p>
                    Press ‘Confirm’ to proceed or ‘Edit’ to amend your details.
                  </p>
                  {this.state.regcust_error != "" && (
                    <p className="error_info">{this.state.regcust_error}</p>
                  )}
                  <div className="up-frm-btn">
                    <a
                      href={void 0}
                      className="button ghost-btn"
                      onClick={this.goBackTo.bind(this, "regphone")}
                    >
                      Edit
                    </a>
                    <a
                      href={void 0}
                      className="button cust-reg-btn"
                      onClick={this.confirmPhone.bind(this)}
                    >
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            )}

            {this.state.bottompopup == "regotp" && (
              <div className="enter-otp">
                <h2>Enter OTP</h2>
                <div className="four-bx-col">
                  <div className="four-bx">
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt1"
                      id="reg_otp_dgt1"
                      value={this.state.regotpdata.reg_otp_dgt1}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt2"
                      id="reg_otp_dgt2"
                      value={this.state.regotpdata.reg_otp_dgt2}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt3"
                      id="reg_otp_dgt3"
                      value={this.state.regotpdata.reg_otp_dgt3}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt4"
                      id="reg_otp_dgt4"
                      value={this.state.regotpdata.reg_otp_dgt4}
                      onChange={this.handleFldChange.bind(this)}
                    />
                  </div>
                  {this.displayRunNumbar()}
                </div>
                {this.state.trigger_otp != "Yes" && (
                  <div className="otp-checkbox">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          name="regotp_terms"
                          onChange={this.changeRegOtpChkBox.bind(this, "trams")}
                          checked={this.chakRegOtpChkBox("trams")}
                        />
                        <p>
                          I confirm I have read and accept the{" "}
                          <a href={void 0}>Terms & Conditions</a>,{" "}
                          <a href={void 0}>Privacy Policy</a> and the{" "}
                          <a href={void 0}>PDPA consent</a>.
                        </p>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="regotp_promo"
                          onChange={this.changeRegOtpChkBox.bind(this, "promo")}
                          checked={this.chakRegOtpChkBox("promo")}
                        />
                        <p>
                          I'd like to receive news, updates and promotions from
                          Buzzr via email and sms
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
                {this.state.trigger_otp === "Yes" ? (
                  <div className="up-frm-btn">
                    <a
                      href={void 0}
                      className="button ghost-btn"
                      onClick={this.cancelAct.bind(this)}
                    >
                      Cancel
                    </a>
                    <a
                      href={void 0}
                      className="button cust-reg-btn"
                      onClick={this.tgrVerifyOtp.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                ) : (
                  <div className="bottom-btn textcenter">
                    <a
                      href={void 0}
                      className="button full-btn reg-otp-verify"
                      onClick={this.regVerifyOtp.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                )}
              </div>
            )}

            {this.state.bottompopup == "regpersonal" && (
              <div className="update-personal">
                <div className="up-header textcenter">
                  <p>Update your personal details</p>
                </div>
                <div className="up-form">
                  <input
                    type="text"
                    placeholder="Name"
                    name="cust_name"
                    value={this.state.cust_name}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="cust_email_id"
                    value={this.state.cust_email_id}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <select
                    name="birthday_month"
                    value={this.state.birthday_month}
                    className="components_selct components_selctbox_cls"
                    onChange={this.handleFldChange.bind(this)}
                  >
                    <option value="">Birthday Month</option>
                    <option value="january">January</option>
                    <option value="february">February</option>
                    <option value="march">March</option>
                    <option value="april">April</option>
                    <option value="may">May</option>
                    <option value="june">June</option>
                    <option value="july">July</option>
                    <option value="august">August</option>
                    <option value="september">September</option>
                    <option value="october">October</option>
                    <option value="november">November</option>
                    <option value="december">December</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Referral Code"
                    name="cust_ref_code"
                    value={this.state.cust_ref_code}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  {this.state.regcust_error != "" && (
                    <p className="error_info">{this.state.regcust_error}</p>
                  )}
                  <div className="up-frm-btn">
                    <a
                      href={void 0}
                      className="button ghost-btn"
                      onClick={this.goBackTo.bind(this, "regphone")}
                    >
                      Cancel
                    </a>
                    <a
                      href={void 0}
                      className="button cust-reg-btn"
                      onClick={this.custRegistration.bind(this)}
                    >
                      Register
                    </a>
                  </div>
                </div>
              </div>
            )}

            {this.state.bottompopup == "loginfrm" && (
              <div className="welcome-phone login-frm">
                <div className="wp-top">
                  <h2>Login to EpicPay</h2>
                  <p>Enter your phone number to and login to your account</p>
                </div>
                <div className="input-phone">
                  <div className="prefix-merge">
                    <div className="prefix">+65</div>
                    <div className="nxt-fix">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_phone_no"
                        value={this.state.reg_phone_no}
                        placeholder="Phone number"
                        onChange={this.handleFldChange.bind(this)}
                      />
                    </div>
                  </div>
                  {this.state.regphone_error != "" && (
                    <span className="error-info">
                      {this.state.regphone_error}
                    </span>
                  )}
                  <p>
                    We will send you a one-time SMS, Additional carriers charges
                    may apply
                  </p>
                </div>
                <div className="bottom-btn bb-txt textcenter">
                  <a
                    href={void 0}
                    className="button full-btn login-otp-link"
                    onClick={this.regSendOtp.bind(this, "login")}
                  >
                    Login
                  </a>
                  <p>
                    Do not have an account ?{" "}
                    <a
                      href={void 0}
                      onClick={this.goBackTo.bind(this, "regphone")}
                    >
                      Register here
                    </a>
                  </p>
                </div>
              </div>
            )}

            {this.state.bottompopup == "loginotp" && (
              <div className="enter-otp enter-otp-login">
                <h2>Enter OTP</h2>
                <div className="four-bx-col">
                  <div className="four-bx">
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt1"
                      id="reg_otp_dgt1"
                      value={this.state.regotpdata.reg_otp_dgt1}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt2"
                      id="reg_otp_dgt2"
                      value={this.state.regotpdata.reg_otp_dgt2}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt3"
                      id="reg_otp_dgt3"
                      value={this.state.regotpdata.reg_otp_dgt3}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="reg_otp_dgt4"
                      id="reg_otp_dgt4"
                      value={this.state.regotpdata.reg_otp_dgt4}
                      onChange={this.handleFldChange.bind(this)}
                    />
                  </div>
                  {this.displayRunNumbar()}
                </div>
                <div className="bottom-btn">
                  <a
                    href={void 0}
                    className="button full-btn"
                    onClick={this.loginVerifyOtp.bind(this)}
                  >
                    Login
                  </a>
                </div>
              </div>
            )}
          </div>
        </BottomSheet>
      </div>
    );
  }
}



const mapStateTopProps = (state) => {
  var rewardsettingsArr = [];
  if (Object.keys(state.rewardsettingslist).length > 0) {
    if (state.rewardsettingslist[0].status === "ok") {
      rewardsettingsArr = state.rewardsettingslist[0].result;
    }
  }

  return {
    rewardsettingslist: rewardsettingsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRewardSettingList: (params) => {
      dispatch({ type: GET_REWARDSETTING_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(DirectPayHome));

