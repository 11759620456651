import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import update from "immutability-helper";
import axios from "axios";
import $ from "jquery";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import cookie from "react-cookies";
import Swal from "sweetalert2";
import { GET_CUSTOMER_DETAILS, GET_PRODUCT_LIST } from "../../actions";
import { apiUrl, unquieID, baseUrl } from "../Settings/Config";
import {
  hideLoader,
  showAlert,
  showLoader,
  showPriceSeparate,
  showLoaderLst,
  hideLoaderLst,
  addressFormat,
  encodeValue,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import googlePay from "../../common/images/payment/google-pay.svg";
import amexPay from "../../common/images/payment/american-express.svg";
import applePay from "../../common/images/payment/apple_logo.svg";
import atome from "../../common/images/payment/atome.svg";
import paynow from "../../common/images/payment/PayNowLogo.svg";
import grabpay from "../../common/images/payment/GrabPay.svg";
import mastercard from "../../common/images/payment/mastercard.svg";
import visa from "../../common/images/payment/visa.svg";
import mswipe from "../../common/images/payment/mswipe.svg";
import cellphone1 from "../../common/images/cellphone1.svg";
import walletlight from "../../common/images/wallet.svg";
import qrcodepay from "../../common/images/payment/qr-code-pay.svg";
import arrow from "../../common/images/arrow.png";
import reloadQr from "../../common/images/reload_qr.png";
import selectedVoucher from "../../common/images/selectedVoucher.png";
import blueround from "../../common/images/blueround.png";
import startop from "../../common/images/star-top.png";
import starbottom from "../../common/images/star-bottom.png";

var qs = require("qs");
var Parser = require("html-react-parser");
var downloadTimer = "";
class Checkout extends Component {
  constructor(props) {
    super(props);
    var UserId = cookie.load("UserId") || "";
    var outletID = cookie.load("outletID") || "";
    this.state = {
      designType: "new",
      withheaderbg: "yes",
      allowToShowCompany: "yes",
      tableNumber: "",
      bottompopup: "regphone",
      voucherList: [],
      displyVoucher: "",
      typeofCreditRedeem: 1,
      billPayAmount: this.props.location?.state?.billPayAmount ||"", 
      current_page: "Direct Pay",
      checkoutType: "BillPay",
      clientLogo: "",
      companyName: "",
      outletAddresss: "",
      backLink: "",
      UserId: UserId,
      customerData: [],
      plan_data: "",
      plan_qty: 1,
      plan_subtotal: 0,
      plan_gst: 0,
      plan_gst_amount: 0,
      plan_grandtotal: 0,
      terms_conditions: "no",
      promotions_updates: "no",
      error_msg: "",
      stripe_loaded: 0,
      stripe_log: 0,
      qrcodeData: [],
      qrcode_str: "",
      qrCode: "",
      runingNum: 0,
      inititalLoad: true,
      productList: [],
      productName: "",
      updatedQty: false,
      startbuyVoucher: false,
      paymentMethod: "",
      manualPayment: false,
      mswipePayment: false,
      reddotPayment: false,
      savecard: false,
      gSettings: "",
      selectedVoucher: "",
      signFrm: false,

      reg_phone_no: "",
      reg_phone_no_error: "",
      regotpdata: {
        reg_otp_dgt1: "",
        reg_otp_dgt2: "",
        reg_otp_dgt3: "",
        reg_otp_dgt4: "",
      },
      regotp_error: "",
      regotp_terms: "No",
      regotp_promo: "No",
      trigger_otp: "No",

      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      regcust_error: "",

      PayAmount:  0,
      balanceAmount: 0,
      bonusAmount: 0,
      reciveAmount: 0,
      voucherBalance: 0,
      continueBill: false,
      regphone_error: "",
      temppaymentMethod: "",
      tax_percentage: "",
      buyvoucher_id: "",
      outletList: "",
      outletID: outletID,
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
    this.inputRef = React.createRef();
    this.props.getCustomerDetails("&customer_id=" + UserId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSettings();
    this.getProductData();
    if(this.state.outletID==="") {
      this.getOutlet();
    }    

    setTimeout(() => {
      if (this.inputRef.current) {
        this.inputRef.current.addEventListener("wheel", this.disableScroll, {
          passive: false,
        });
      }
    }, 1000);
  }
  componentWillUnmount() {
    // Remove the wheel event listener when the component unmounts
    setTimeout(() => {
      if (this.inputRef.current) {
        this.inputRef.current.removeEventListener("wheel", this.disableScroll);
      }
    }, 1000);
  }

  disableScroll = (event) => {
    event.preventDefault(); // Prevents the scroll action from changing the input value
  };
  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails }, () => {
        this.calculateAmt();
        if (this.state.continueBill === true) {
          this.setState({ continueBill: false }, () => {
            if (this.state.temppaymentMethod === "QR Payment") {
              this.setState(
                {
                  temppaymentMethod: "",
                  paymentMethod: "QR Payment",
                },
                () => {
                  this.generateCustQrcode();
                  $("html, body").animate(
                    {
                      scrollTop: $(".checkout-qr").offset().top,
                    },
                    2000
                  );
                }
              );
            } else {
              this.payBill();
            }
          });
        }
      });
    }
  }

  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?unquieID=" + unquieID;
    var outletID = cookie.load("outletID") || "";
    if (outletID !== "") {
      urlShringTxt += "&outletID=" + outletID;
    }
    axios
      .get(urlShringTxt, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          
          var manualPayment = result?.enable_manual_payment
            ? Boolean(result?.enable_manual_payment)
            : false;
          var paymentMethod = "";

          var mswipePayment = result?.enable_mswipe_payment
            ? (result.enable_mswipe_payment==='true')?true:false
            : false;
          var reddotPayment = result?.enable_reddot_payment
            ? (result.enable_reddot_payment==='true')?true:false
            : false;
          var reddotmv3ds = result?.enable_reddot_mv3ds && reddotPayment===true
            ? Boolean(result?.enable_reddot_mv3ds)
            : false;
          if (paymentMethod === "" && reddotmv3ds === true) {
            paymentMethod = "mv3ds";
          }
          var reddotamex3ds = result?.enable_reddot_amex3ds && reddotPayment===true
            ? Boolean(result?.enable_reddot_amex3ds)
            : false;
          if (paymentMethod === "" && reddotamex3ds === true) {
            paymentMethod = "amex3ds";
          }
          var reddotapplepay = result?.enable_reddot_applepay && reddotPayment===true
            ? Boolean(result?.enable_reddot_applepay)
            : false;
          if (paymentMethod === "" && reddotapplepay === true) {
            paymentMethod = "applepay";
          }
          var reddotatome = result?.enable_reddot_atome && reddotPayment===true
            ? Boolean(result?.enable_reddot_atome)
            : false;
          if (paymentMethod === "" && reddotatome === true) {
            paymentMethod = "atome";
          }
          var reddotgooglepay = result?.enable_reddot_googlepay && reddotPayment===true
            ? Boolean(result?.enable_reddot_googlepay)
            : false;
          if (paymentMethod === "" && reddotgooglepay === true) {
            paymentMethod = "googlepay";
          }
          var reddotgrabpay = result?.enable_reddot_grabpay && reddotPayment===true
            ? Boolean(result?.enable_reddot_grabpay)
            : false;
          if (paymentMethod === "" && reddotgrabpay === true) {
            paymentMethod = "grabpay";
          }
          var reddotpaynow = result?.enable_reddot_paynow && reddotPayment===true
            ? Boolean(result?.enable_reddot_paynow)
            : false;
          if (paymentMethod === "" && reddotpaynow === true) {
            paymentMethod = "paynow";
          }

          if (paymentMethod === "" && mswipePayment === true) {
            paymentMethod = "MSwipe";
          }
          if (
            navigator.userAgent.indexOf("Mac") !== -1 &&
            reddotapplepay === true
          ) {
            paymentMethod = "applepay";
          }
          var outlet = result?.outlet || "";
          var displyAddress = "";
          if (outlet !== "") {
            displyAddress = addressFormat(
              outlet.outlet_unit_number1,
              "",
              outlet.outlet_address_line1,
              "",
              outlet.outlet_postal_code
            );
          }

          this.setState({
            gSettings: result,
            manualPayment: manualPayment,
            mswipePayment: mswipePayment,
            reddotPayment: reddotPayment,
            paymentMethod: paymentMethod,
            reddotmv3ds: reddotmv3ds,
            reddotamex3ds: reddotamex3ds,
            reddotapplepay: reddotapplepay,
            reddotatome: reddotatome,
            reddotgooglepay: reddotgooglepay,
            reddotgrabpay: reddotgrabpay,
            reddotpaynow: reddotpaynow,
            typeofCreditRedeem: result?.typeofCreditRedeem || 1,
            clientLogo: result?.company_logo,
            companyName: result?.company_name,
            outletAddresss: displyAddress,
          }, ()=> {
            var billPayAmount_ = this.props.location?.state?.billPayAmount || '';
            var allowWalletPay_ = this.props.location?.state?.allowWalletPay || 'No';
            if(billPayAmount_!=="" && allowWalletPay_=="Yes") {
              this.selectPaymentMethod('voucherpay')
            }
          });
        }
      });
  }

  getOutlet() {
    var urlShringTxt = apiUrl + "settings/getOutlet/?unquieID=" + unquieID;
    axios
      .get(urlShringTxt, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          if (result.length > 0) {
            if (result.length > 1) {
              var outletList = '<option value="">Select Outlet</option>';
              result.map((item) => {
                outletList += `<option value="${item.value}">${item.label}</option>`;
              });
              this.setState({ outletList: outletList });
            } else {
              cookie.save("outletID", result[0].value, { path: "/" });
            }
          }
        }
      });
  }

  payBill() {
    var unquie_IDs = unquieID;
    let customer_id = this.state.UserId;
    let subtotal_amount = this.state.billPayAmount;
    let total_amount = this.state.plan_grandtotal;
    let tax_percentage = this.state.tax_percentage;
    var outletID = cookie.load("outletID") || "";
    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    } else if (outletID === "") {
      isErrorMsg = "Please select any one Outlet";
    }
    

    if (isErrorMsg === "") {
      const loginType = cookie.load("loginType") || "";
      if (loginType === "Guest" && this.state.selectedVoucher !== "") {
        this.setState({ signFrm: true, continueBill: true });
      } else {
       
        this.setState({ error_msg: "" });
        let subtotal_amount = 0;
        let productID = "";
        if (this.state.selectedVoucher !== "") {
          let voucher = this.state.voucherList[this.state.selectedVoucher];
          subtotal_amount = voucher.product_price;
          productID = voucher.product_id;
        } else {
          subtotal_amount = total_amount;
        }
        var DirectQRCode = cookie.load("DirectQRCode") || "";

        var postObject = {
          app_id: unquie_IDs,
          customer_id: this.state.UserId,
          subtotal_amount: subtotal_amount,
          total_amount: total_amount,
          tax_percentage: tax_percentage,
          terms_conditions: "yes",
          promotions_updates: "no",
          checkoutType: this.state.checkoutType,
          callbackurl: baseUrl,
          savecard: "N",
          outletID: outletID,
          paymentMethod: this.state.paymentMethod,
          productID: productID,
          billPayAmount: this.state.billPayAmount,
          reciveAmount: this.state.reciveAmount,
          gstAmount: this.state.plan_gst_amount,
          directQRCode: DirectQRCode,
        };
        console.log(postObject, "postObjectpostObject");
        //return false;

        if (outletID !== "") {
          showLoader("placeOredr");
          if (this.state.paymentMethod === "voucherpay") {
            this.comfirmRedeemFun();
          } else if (this.state.paymentMethod === "MSwipe") {
            var post_Object = {
              app_id: unquieID,
              qr_type: this.state.checkoutType,
              customer_id: this.state.UserId,
              payment_type: this.state.checkoutType,
              callbackurl: baseUrl + "placeorder",
              paid_amount: total_amount,
            };

            axios
              .post(
                apiUrl + "paymentV2/createPaymentLink",
                qs.stringify(post_Object),
                {
                  headers: { Authorization: localStorage.getItem("token") },
                }
              )
              .then((res) => {
                hideLoader("placeOredr");
                if (res.data.status === "ok") {
                  var productList = this.state.productList[0];
                  localStorage.setItem(
                    "paymentRequestId",
                    res.data.payment_requestId
                  );
                  localStorage.setItem(
                    "voucherType",
                    productList.product_voucher
                  );
                  setTimeout(function () {
                    window.location.href = res.data.redirectURL;
                  }, 500);
                }
              });
          } else {
            axios
              .post(
                apiUrl + "paymentV2/processReddotPayment",
                qs.stringify(postObject),
                {
                  headers: { Authorization: localStorage.getItem("token") },
                }
              )
              .then((res) => {
                hideLoader("placeOredr");
                if (res.data.status === "ok") {
                  localStorage.setItem(
                    "paymentRequestIdRedtod",
                    res.data.payment_requestId
                  );
                  window.location.href = res.data.redirectURL;
                } else {
                  showAlert("Alert", res.data.message, "error");
                }
              });
          }
        } else {
          localStorage.setItem("scanType", "paybill");
          var details = postObject;
          /* this.props.history.push({
            pathname: "/redeemqr/OUT-94197-4-3",
            state: details,
          }); */

          this.props.history.push({
            pathname: "/scanqrcode",
            state: details,
          });
        }
      }
    } else {
      this.setState({ error_msg: isErrorMsg });
    }
  }

  comfirmRedeemFun() {
    let userCredits = this.state.billPayAmount;
    if (parseFloat(userCredits) > 0) {
      var postObject = {
        app_id: unquieID,
        cust_qr_str: this.state.qrCode,
        user_outlet_id: cookie.load("outletID"),
        transUserID: cookie.load("UserId"),
        user_credits: userCredits,
      };
      showLoader("placeOredr");
      axios
        .post(apiUrl + "customer/redeemCashQrcode", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("placeOredr");
          if (res.data.status === "ok") {
            Swal.fire({
              icon: "success",
              title: "Redeemed!",
              text: "Cash Voucher has been redeemed succuessfully.",
              customClass: {
                confirmButton: "btn btn-success waves-effect",
              },
            });
            this.props.history.push("/myaccount");
          } else {
            this.setState({ redeemqr_error: res.data.message });
          }
        });
    } else {
      this.setState({ redeemqr_error: "Invalide QR" });
    }
  }

  selectPaymentMethod(paymentMethod) {
    var processSelect = "Y";
    var outletID = cookie.load("outletID") || "";

    this.setState({ temppaymentMethod: paymentMethod });
    if(outletID==="") {
      Swal.fire({
        title: "Error",
        html: "Please select any one outlet",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary waves-effect waves-light",
        },
        buttonsStyling: false,
      });
      return false;
    }
    if (paymentMethod === "QR Payment"  || paymentMethod === "voucherpay") {
      if (this.state.billPayAmount === "") {
        processSelect = "N";
      } else if (this.state.selectedVoucher !== "") {
        if (cookie.load("loginType") === "Guest") {
          this.setState({ signFrm: true, continueBill: true });
          return false;
        }
      }
      
    }
    if (processSelect === "Y") {
      if(paymentMethod === "voucherpay") {
        var IsVerifiedUser = cookie.load("IsVerifiedUser") || "";
        if(IsVerifiedUser!=="Yes") {
          cookie.save("triggerOTP", "Yes", { path: "/" });
          cookie.save("triggerFrom", "/redeem", { path: "/" });
          const paydetails = {
            billPayAmount: this.state.billPayAmount,  
            triggerFrom:'/directpay/checkout',
            allowWalletPay:'Yes'   
          };
          this.props.history.push({
            pathname: "/",
            state: paydetails,
          });
          return false;
        }
      }
      var allowRest = 'no';
      if(this.state.paymentMethod!==paymentMethod) {
        allowRest = 'yes'
      }

      this.setState({ paymentMethod}, () => {
        console.log(downloadTimer, 'downloadTimer')
        clearInterval(downloadTimer);
        if (paymentMethod === "QR Payment" || paymentMethod === "voucherpay") {
          if(allowRest==="yes") {
            this.setState({runingNum: 0 })
            this.generateCustQrcode('Yes');
          }          
        }
      });
    } else {
      Swal.fire({
        title: "Error",
        html: "Please Enter Bill Pay Amount",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary waves-effect waves-light",
        },
        buttonsStyling: false,
      });
    }
  }

  getProductData() {
    axios
      .get(
        `${apiUrl}products/products_list?app_id=${unquieID}&product_type=7&recomentedVoucher=Yes`,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ voucherList: res.data.result_set }, () => {
            var selectedVoucherss =
              this.props.location.state?.selectedVoucher !== "" &&
              typeof this.props.location.state?.selectedVoucher !== undefined &&
              typeof this.props.location.state?.selectedVoucher !== "undefined"
                ? this.props.location.state?.selectedVoucher
                : "";
            this.setState({ selectedVoucher: selectedVoucherss }, () => {
              this.displyVoucher();
            });
          });
        }
      });
  }
  displyVoucher() {
    if (this.state.voucherList.length > 0) {
      var totalVoucher = 0;
      var i = 0;
      var voucherLis = this.state.voucherList.map((item, index) => {
        var billPayAmount =
          this.state.billPayAmount !== ""
            ? parseInt(this.state.billPayAmount)
            : 0;
        if (
          billPayAmount < parseFloat(item.product_price) ||
          billPayAmount === 0
        ) {
          if(i<=2) {
          totalVoucher++;
          i++;
          return (
            <div key={index}>
              <div
                className={`voucher${
                  item.giftProduct !== null ? " avail-free" : ""
                } ${this.state.selectedVoucher === index ? "selected" : ""}`}
                onClick={this.selectVoucher.bind(this, index)}
              >
                <div>
                  {this.state.selectedVoucher === index ? (
                    <>
                      <img src={selectedVoucher} className="tickimg" />
                      <img src={startop} className="startop" />
                    </>
                  ) : (
                    <img src={blueround} className="tickimg" />
                  )}
                  <h2>
                    <span>{item.discountPrecentage}%</span>
                    <br />
                    {this.state.typeofCreditRedeem === "2"
                      ? "Discount"
                      : "Bonus"}
                  </h2>
                </div>
                <div className="pay-amount-parent">
                  <p>Pay</p>
                  <p className="pay-amount">
                    {showPriceSeparate(item.product_price, 0, "no")}
                  </p>
                </div>

                <p className="plus">+</p>

                <div className="bonus">
                  <p>Bonus</p>
                  <p className="bonus-amount">
                    {this.state.typeofCreditRedeem === "2"
                      ? item.discountPrecentage + "%"
                      : showPriceSeparate(
                          item.product_voucher_bonus_amount,
                          0,
                          "no"
                        )}
                  </p>
                  {this.state.selectedVoucher === index && (
                    <img src={starbottom} className="starbottom" />
                  )}
                </div>
              </div>
              {item.giftProduct !== null && (
                <div className="free-vouchers">
                  + FREE {item.giftProduct.product_name}
                </div>
              )}
            </div>
          );
          }else {
            return "";
          }
        } else {
          return "";
        }
      });
      if (totalVoucher > 0) {
        var displyVouchers = (
          <div className="vouchersList">
            <h1>Enjoy Huge Saving</h1>
            <div className="vouchers ">{voucherLis}</div>
            {this.state.selectedVoucher === "" && (
              <div className="arrow">
                <img src={arrow} alt="Arrow" />
                <p>See how this works</p>
              </div>
            )}
          </div>
        );
        this.setState({ displyVoucher: displyVouchers });
      } else {
        this.setState({ displyVoucher: "" });
      }
    }
  }
  handleFldChange(e) {
    const re = /^[0-9 \b]+$/;
    let name = e.target.name;
    if (
      e.target.name === "reg_otp_dgt1" ||
      e.target.name === "reg_otp_dgt2" ||
      e.target.name === "reg_otp_dgt3" ||
      e.target.name === "reg_otp_dgt4"
    ) {
      if (e.target.value === "" || re.test(e.target.value)) {
        var mblnumber = this.space(e.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 1) {
          let field = e.target.name;
          let fieldvalue = e.target.value;
          this.setState(
            update(this.state, {
              regotpdata: { [field]: { $set: fieldvalue } },
            }),
            function () {
              if (field === "reg_otp_dgt1" && mblnumberLenght === 1) {
                $("#reg_otp_dgt2").focus();
              }
              if (field === "reg_otp_dgt2" && mblnumberLenght === 1) {
                $("#reg_otp_dgt3").focus();
              }
              if (field === "reg_otp_dgt3" && mblnumberLenght === 1) {
                $("#reg_otp_dgt4").focus();
              }
            }
          );
        }
      }
    } else {
      this.setState({ [name]: e.target.value, [`${name}_error`]: "" }, () => {
        if (name === "billPayAmount") {
          var selectedVoucher = this.state.selectedVoucher;
          if (selectedVoucher !== "" && this.state.billPayAmount !== "") {
            var existVoucher = this.state.voucherList[selectedVoucher];
            if (
              parseFloat(this.state.billPayAmount) >
              parseFloat(existVoucher.product_price)
            ) {
              selectedVoucher = "";
            }
          }
          this.setState({ selectedVoucher: selectedVoucher }, () => {
            this.displyVoucher();
            this.calculateAmt();
          });
        } else if (name === "outletID") {
          cookie.save("outletID", e.target.value, { path: "/" });
          setTimeout(() =>  {
            if(this.state.temppaymentMethod!=="") {
              this.selectPaymentMethod(this.state.temppaymentMethod);
            }
          }, 500);
        } else {
          this.calculateAmt();
        }
      });
    }
  }
  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }
  calculateAmt() {
    let payAmount = this.state.billPayAmount || 0;
    let balanceAmount = "";
    let bonusAmount = "";
    let reciveAmount = "";
    if (this.state.selectedVoucher !== "") {
      let voucher = this.state.voucherList[this.state.selectedVoucher];
      payAmount = voucher.product_price;
      reciveAmount = voucher.totalCridit;
      balanceAmount = reciveAmount - this.state.billPayAmount;
      if (this.state.typeofCreditRedeem === "2") {
        bonusAmount = voucher.product_voucher_bonus_amount;
      }
    }
    let customerData = this.state.customerData || "";
    let voucherBalance = 0;
    if (customerData !== "") {
      if (customerData?.custmap_available_credits) {
        voucherBalance = customerData?.custmap_available_credits;
      }
    }
    let plan_grandtotal = parseFloat(payAmount);
    this.setState({
      plan_grandtotal: plan_grandtotal,
      PayAmount: payAmount,
      balanceAmount: balanceAmount,
      reciveAmount: reciveAmount,
      voucherBalance: voucherBalance,
      bonusAmount: bonusAmount,
    });
  }
  selectVoucher(selectedVoucher) {
    if (this.state.selectedVoucher === selectedVoucher) {
      this.setState({ selectedVoucher: "" }, () => {
        this.calculateAmt();
        this.displyVoucher();
      });
    } else {
      this.setState({ selectedVoucher }, () => {
        this.calculateAmt();
        this.displyVoucher();
      });
    }
  }

  goBackTo(refPage, event) {
    event.preventDefault();
    var regotpdata = {
      reg_otp_dgt1: "",
      reg_otp_dgt2: "",
      reg_otp_dgt3: "",
      reg_otp_dgt4: "",
    };
    this.setState({
      bottompopup: refPage,
      reg_phone_no_error: "",
      regphone_error: "",
      regotpdata: regotpdata,
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      cust_ref_code: "",
      regcust_error: "",
    });
  }

  regSendOtp(typeflg, event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    if (regphoneNo !== "") {
      var postObject = {
        customer_mobile: regphoneNo,
        otp_type: typeflg,
      };
      let loadrdivcls = typeflg === "login" ? "login-otp-link" : "reg-otp-link";
      showLoaderLst(loadrdivcls, "class");
      axios
        .post(apiUrl + "customer/send_customer_otp", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst(loadrdivcls, "class");
          if (res.data.status === "ok") {
            if (
              typeflg === "login" ||
              (typeflg === "register" && res.data.is_existing_user === "yes")
            ) {
              let custArr = res.data.result;
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.remove("loginType", { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("UserMobile", regphoneNo, { path: "/" });
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
              if (unquieID !== "") {
                localStorage.setItem("allowCompanyRedirect", "Yes");
              }
              this.setState(
                {
                  signFrm: false,
                  UserId: custArr.customer_id,
                },
                () => {
                  this.calculateAmt();
                  this.props.getCustomerDetails(
                    "&customer_id=" + custArr.customer_id
                  );
                }
              );
            } else {
              this.setState({ bottompopup: "confirm" });
            }
          } else {
            this.setState({ reg_phone_no_error: res.data.message });
          }
        });
    } else {
      this.setState({ reg_phone_no_error: "Phone number required" });
    }
  }

  custRegistration(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var errorMgs = "";
    if (regphoneNo === "") {
      errorMgs = "Mobile No required";
    } else if (this.state.cust_name === "") {
      errorMgs = "Name required";
    } else if (this.state.cust_email_id === "") {
      errorMgs = "Email required";
    } else if (this.state.birthday_month === "") {
      errorMgs = "Birthday month required";
    }

    if (errorMgs === "") {
      var postObject = {
        app_id: unquieID,
        customer_phone: regphoneNo,
        customer_email: this.state.cust_email_id,
        customer_birthmonth: this.state.birthday_month,
        customer_ref_code: this.state.cust_ref_code,
        customer_name: this.state.cust_name,
      };
      showLoaderLst("cust-reg-btn", "class");
      axios
        .post(apiUrl + "customer/registration", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("cust-reg-btn", "class");
          if (res.data.status === "ok") {
            let custArr = res.data.result_set;
            cookie.save("UserId", custArr.customer_id, { path: "/" });
            cookie.save("UserEmail", custArr.customer_email, { path: "/" });
            cookie.remove("loginType", { path: "/" });
            cookie.save(
              "UserFname",
              custArr.customer_first_name !== ""
                ? custArr.customer_first_name
                : "",
              { path: "/" }
            );
            cookie.save(
              "UserLname",
              custArr.customer_last_name !== ""
                ? custArr.customer_last_name
                : "",
              { path: "/" }
            );
            cookie.save("UserMobile", regphoneNo, { path: "/" });
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            localStorage.setItem("token", custArr?.token);

            this.setState(
              {
                signFrm: false,
                UserId: custArr.customer_id,
              },
              () => {
                this.calculateAmt();
                this.props.getCustomerDetails(
                  "&customer_id=" + custArr.customer_id
                );
              }
            );
          } else {
            this.setState({ regcust_error: res.data.message });
          }
        });
    } else {
      this.setState({ regcust_error: errorMgs });
    }
  }

  confirmPhone(event) {
    event.preventDefault();
    this.setState(
      { bottompopup: "regotp", runingNum: 120 },
      function () {
        this.setRuningNum();
      }.bind(this)
    );
  }
  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }
  displayRunNumbar() {
    if (this.state.regotp_error !== "") {
      return <p className="error_info">{this.state.regotp_error}</p>;
    } else if (this.state.runingNum > 0) {
      return (
        <p className="runing_num">Resend in {this.state.runingNum} Seconds</p>
      );
    } else {
      return <p className="resend_link">Resend</p>;
    }
  }

  changeRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field === "trams") {
      var regotpterms = regotp_terms === "Yes" ? "No" : "Yes";
      this.setState({ regotp_terms: regotpterms });
      if (regotp_terms === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }

    if (field === "promo") {
      var regotpPromo = regotp_promo === "Yes" ? "No" : "Yes";
      this.setState({ regotp_promo: regotpPromo });
      if (regotpPromo === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }
  }

  chakRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    var chkBox = "";
    if (field === "trams") {
      chkBox = regotp_terms === "Yes" ? true : false;
      return chkBox;
    } else if (field === "promo") {
      chkBox = regotp_promo === "Yes" ? true : false;
      return chkBox;
    }
  }
  cancelAct(event) {
    event.preventDefault();
    this.setState({ signFrm: false });
  }
  openLogin(e) {
    e.preventDefault();
    this.setState({ signFrm: true });
  }
  regVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length !== 4) {
      errorMgs = "Please enter the OTP";
    } else if (this.state.regotp_terms !== "Yes") {
      errorMgs = "Terms & Conditions required";
    } else if (this.state.regotp_promo !== "Yes") {
      errorMgs = "Promotions required";
    }

    if (regphoneNo !== "" && errorMgs === "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "register",
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            this.setState({ bottompopup: "regpersonal", runingNum: 0 });
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }

  generateCustQrcode(allowScroll='No') {
    var postObject = {};
    postObject = {
      app_id: unquieID,
      qr_type: "BillPay",
      customer_id: this.state.UserId,
      billAmount: this.state.billPayAmount,
    };
    if (this.state.selectedVoucher !== "") {
      let productList = this.state.voucherList[this.state.selectedVoucher];
      const productID = productList.product_primary_id;
      postObject["voucher_id"] = productID;
      postObject["voucher_quantity"] = 1;
    }

    axios
      .post(apiUrl + "customer/generateCustQrcode", qs.stringify(postObject), {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          let qrData =
            res.data.common.image_source +
            "/" +
            res.data.result_set.cust_qr_image;
          this.setState(
            {
              qrcodeData: res.data.result_set,
              qrcode_str: qrData,
              qrCode: res.data.result_set.cust_qr_code,
              runingNum: 25,
            },
            function () {
              if(allowScroll==="Yes") {
                $("html, body").animate(
                  {
                    scrollTop: $(".checkout-qr").offset().top,
                  },
                  2000
                );
              }
              

              this.setRuningNum();
              /*  if (this.state.inititalLoad === true) {
                this.setState({ inititalLoad: false }, () => { */
              this.checkingQRstatus();
              /*   });
              } */
            }.bind(this)
          );
        }
      });
  }

  reloadQrFun(event) {
    event.preventDefault();
    this.generateCustQrcode();
  }

  checkingQRstatus() {
    var postObject = {};
    postObject = {
      app_id: unquieID,
      qrcode: this.state.qrCode,
      customer_id: this.state.UserId,
    };
    axios
      .post(apiUrl + "customer/checkRedeemStatus", qs.stringify(postObject), {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        var currentThis = this;
        if (res.data.status === "ok") {
          if (this.state.runingNum > 0) {
            setTimeout(function () {
              currentThis.checkingQRstatus();
            }, 3000);
          }
        } else if (res.data.status === "used") {
          this.setState({ cust_qr_user_id: res.data.cust_qr_user_id }, () => {
            if (this.state.selectedVoucher !== "") {
              this.buyVoucher();
            } else {
              this.payBillQR();
            }
          });
        } else {
        }
      });
  }

  payBillQR() {
    var postObject = {};
    postObject = {
      app_id: unquieID,
      cust_qr_str: this.state.qrCode,
      customer_id: this.state.UserId,
      billAmount: this.state.billPayAmount,
      user_outlet_id: cookie.load("outletID"),
      qr_user_id: this.state.cust_qr_user_id,
      buyVoucher: this.state.selectedVoucher !== "" ? "Yes" : "No",
      paymentMethod: this.state.paymentMethod,
      buyvoucher_id: this.state.buyvoucher_id,
    };

    axios
      .post(apiUrl + "customer/billPay", qs.stringify(postObject), {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          Swal.fire({
            title: "Success",
            html: "Bill paid successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.redirec();
        } else {
        }
      });
  }
  redirec() {
    this.props.history.push({
      pathname: "/directpay/thankyou",
      state: {
        billPayAmount: this.state.billPayAmount,
        companyName: this.state.companyName,
        reciveAmount: this.state.reciveAmount,
        plan_grandtotal: this.state.plan_grandtotal,
        billPayAmount: this.state.billPayAmount,
        selectedVoucher: this.state.selectedVoucher,
        voucherList: this.state.voucherList,
      },
    });
  }

  buyVoucher() {
    var productID = "";
    var unquie_IDs = unquieID;

    let productList = this.state.voucherList[this.state.selectedVoucher];
    productID = encodeValue(productList.product_id);
    unquie_IDs = productList.product_company_unique_id;

    let customer_id = this.state.UserId;
    let plan_qty = 1;
    let subtotal_amount = productList.product_price;
    let total_amount = productList.product_price;
    let tax_percentage = "";
    let terms_conditions = "";
    let promotions_updates = "";

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (productID === "") {
      isErrorMsg = "Product Id was empty";
    } else if (plan_qty === 0 || plan_qty === "") {
      isErrorMsg = "Plan Quantity was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    }

    if (isErrorMsg === "") {
      showLoader("placeOredr");
      this.setState({ error_msg: "" });
      var postObject = {
        app_id: unquie_IDs,
        customer_id: this.state.UserId,
        plan_qty: plan_qty,
        subtotal_amount: subtotal_amount,
        total_amount: total_amount,
        tax_percentage: tax_percentage,
        terms_conditions: terms_conditions,
        promotions_updates: promotions_updates,
        qrcode: this.state.qrCode,
        checkoutType: "buyvoucher",
        paymentMethod: "Manual",
        productID: productID,
      };
      axios
        .post(apiUrl + "wallettopup/topup", qs.stringify(postObject), {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState(
              { buyvoucher_id: res.data.result_set.buyvoucher_id },
              () => {
                localStorage.removeItem("planData");
                this.payBillQR();
              }
            );
          } else {
            Swal.fire({
              title: "Error",
              html: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
            this.props.history.push("/refpage/checkout");
          }
        });
    } else {
      this.setState({ error_msg: isErrorMsg });
    }
  }
  render() {
    if (this.state.gSettings !== "") {
      return (
        <div className="directpay-new">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div className="container">
            <div className="payment-container">
              <div className="order-details">
                {this.state.tableNumber !== "" ? (
                  <div className="bill-section">
                    <div className="item-list">
                      <div className="item">
                        <p>Cafe Late</p>
                        <div className="item-qty-price">
                          <span className="qty">2x</span>
                          <span className="price">$15.00</span>
                        </div>
                      </div>
                      <div className="item">
                        <p>Chicken Burger</p>
                        <div className="item-qty-price">
                          <span className="qty">1x</span>
                          <span className="price">$14.00</span>
                        </div>
                      </div>
                      <div className="item">
                        <p>Coke Can</p>
                        <div className="item-qty-price">
                          <span className="qty">2x</span>
                          <span className="price">$15.00</span>
                        </div>
                      </div>
                      <div className="item">
                        <p>Fusilli</p>
                        <div className="item-qty-price">
                          <span className="qty">1x</span>
                          <span className="price">$18.00</span>
                        </div>
                      </div>
                    </div>

                    <div className="subtotal-section">
                      <div className="subtotal">
                        <span>Subtotal</span>
                        <span>$62.00</span>
                      </div>
                      <div className="service-charge">
                        <span>Service Charge</span>
                        <span>$2.50</span>
                      </div>
                      <div className="gst">
                        <span>GST 9%</span>
                        <span>$5.40</span>
                      </div>
                      <div className="shared">
                        <span>Shared with 1</span>
                        <span>($10.00)</span>
                      </div>
                      <div>
                        <div className="payable">
                          <span>Payable Amount</span>
                          <span>$59.90</span>
                        </div>

                        <small>Inclusive of all taxes and charges</small>
                      </div>
                      <div>
                        <div className="your-share">
                          <span>Your Share</span>
                          <span>$5.00</span>
                        </div>
                        <small>You are paying for your share</small>
                      </div>
                    </div>

                    <div className="actions">
                      <button className="split-bill">Edit Split</button>
                      <button className="pay-total">Pay Total</button>
                    </div>

                    <div className="tip-section">
                      <p>Do you want to include a tip</p>
                      <div className="tip-options">
                        <button className="tip-option">Not Now</button>
                        <button className="tip-option selected">3%</button>
                        <button className="tip-option">5%</button>
                        <button className="tip-option">7%</button>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Custom Amount"
                        className="custom-tip-input"
                      />
                      <div className="tip-amount">
                        <span>Tip amount :</span>
                        <span>$2.50</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="customer-amount-section">
                    <label>Enter Your Amount Here</label>
                    <span>$</span>
                    <input
                      placeholder="Custom Amount"
                      className="custom-tip-input"
                      value={this.state.billPayAmount}
                      autoComplete="off"
                      name="billPayAmount"
                      type="number"
                      min={1}
                      inputMode="decimal"
                      pattern="\d*"
                      onChange={this.handleFldChange.bind(this)}
                      ref={this.inputRef}
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.displyVoucher}

            <div className="payment-sec">
              <div className="details">
                {this.state.selectedVoucher === "" && parseFloat(this.state.voucherBalance) > 0 && cookie.load("loginType") !== "Guest" && (
                    <div className="checkout-voucherpay-parent">
                      <div
                        className={`payment-list checkout-voucherpay ${
                          this.state.paymentMethod === "voucherpay"
                            ? "selected"
                            : ""
                        }`}
                        onClick={this.selectPaymentMethod.bind(
                          this,
                          "voucherpay"
                        )}
                      >
                        <span>
                          <input
                            type="radio"
                            name="payment"
                            checked={
                              this.state.paymentMethod === "voucherpay"
                                ? true
                                : false
                            }
                          />
                          Your Cash Vouchers ({showPriceSeparate(this.state.voucherBalance)})
                        </span>
                        <img
                          src={walletlight}
                          alt="Voucher Pay"
                          className="voucher-pay"
                        />
                      </div>
                    </div>
                  )}

                {this.state.selectedVoucher !== "" && (
                  <>
                    <p>
                      You Pay{" "}
                      <span>{showPriceSeparate(this.state.PayAmount)}</span>
                    </p>
                    <p>
                      Receive{" "}
                      <span>{showPriceSeparate(this.state.reciveAmount)}</span>
                    </p>
                  </>
                )}
                <p>
                  Bill{" "}
                  <span>{showPriceSeparate(this.state.billPayAmount)}</span>
                </p>
                {/* <p>
                  Tip <span>$2.50</span>
                </p> */}
                {this.state.balanceAmount > 0 && (
                  <p>
                    Balance For Next Visit{" "}
                    <span>{showPriceSeparate(this.state.balanceAmount)}</span>
                  </p>
                )}
                <p className="total">
                  Total{" "}
                  <span>{showPriceSeparate(this.state.plan_grandtotal)}</span>
                </p>
              </div>

              <div className="payment-method">
                {this.state.outletList !== "" && (
                  <>
                  <h3>Outlet</h3>
                  <select
                    name="outletID"
                    value={this.state.outletID}
                    className="components_selct components_selctbox_cls"
                    onChange={this.handleFldChange.bind(this)}
                  >
                    {Parser(this.state.outletList)}
                  </select>
                  </>
                )}

                <h3>Payment Method</h3>
                {this.state.paymentMethod==="" &&
                  <span className="error">Please Select Any One Payment Mode</span>
                }
                {this.state.manualPayment === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "QR Payment"
                        ? "selected"
                        : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "QR Payment")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "QR Payment"
                            ? true
                            : false
                        }
                      />
                      QR Payment
                    </span>
                    <div>
                      <img
                        src={qrcodepay}
                        alt="QR Code Pay"
                        className="qr-code-pay"
                      />
                    </div>
                  </div>
                )}
               

                {this.state.reddotmv3ds === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "mv3ds" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "mv3ds")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "mv3ds" ? true : false
                        }
                      />
                      Master / Visa Card
                    </span>
                    <div>
                      <img
                        src={mastercard}
                        alt="MasterCard"
                        className="master-card"
                      />
                      <img src={visa} alt="Visa" className="visa-card" />
                    </div>
                  </div>
                )}
                {this.state.reddotapplepay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "applepay" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "applepay")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "applepay" ? true : false
                        }
                      />
                      Apple Pay
                    </span>
                    <img src={applePay} alt="ApplePay" className="apple-icon" />
                  </div>
                )}
                {this.state.reddotamex3ds === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "amex3ds" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "amex3ds")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "amex3ds" ? true : false
                        }
                      />
                      Amex Pay
                    </span>
                    <img
                      src={amexPay}
                      alt="ApplePay"
                      className="amexpay-icon"
                    />
                  </div>
                )}

                {this.state.reddotgooglepay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "googlepay" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "googlepay")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "googlepay"
                            ? true
                            : false
                        }
                      />
                      Google Pay
                    </span>
                    <img
                      src={googlePay}
                      alt="ApplePay"
                      className="googlepay-icon"
                    />
                  </div>
                )}

                {this.state.reddotapplepay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "atome" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "atome")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "atome" ? true : false
                        }
                      />
                      Atome Pay
                    </span>
                    <img src={atome} alt="ApplePay" className="atome-icon" />
                  </div>
                )}

                {this.state.reddotpaynow === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "paynow" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "paynow")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "paynow" ? true : false
                        }
                      />
                      PayNow
                    </span>
                    <img src={paynow} alt="PayNow" className="paynow-icon" />
                  </div>
                )}

                {this.state.reddotgrabpay === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "grabpay" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "grabpay")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "grabpay" ? true : false
                        }
                      />
                      GrabPay
                    </span>
                    <img src={grabpay} alt="GrabPay" className="grabpay-icon" />
                  </div>
                )}

                {this.state.mswipePayment === true && (
                  <div
                    className={`payment-list ${
                      this.state.paymentMethod === "MSwipe" ? "selected" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "MSwipe")}
                  >
                    <span>
                      <input
                        type="radio"
                        name="payment"
                        checked={
                          this.state.paymentMethod === "MSwipe" ? true : false
                        }
                      />
                      MSwipe
                    </span>
                    <img src={mswipe} alt="ApplePay" className="mswipe-icon" />
                  </div>
                )}
              </div>

              {(this.state.paymentMethod === "QR Payment" ||
                this.state.paymentMethod === "voucherpay") && (
                <div className="card-detail">
                  <div className="cart-detail-header">
                    <h3>Scan QR Code and get amount</h3>
                  </div>
                  <div className="card-detail-body checkout-qr">
                    {this.state.runingNum > 0 ? (
                      <img src={this.state.qrcode_str} alt="QR Code" />
                    ) : (
                      <img
                        src={reloadQr}
                        onClick={this.reloadQrFun.bind(this)}
                        alt="QR Code"
                      />
                    )}

                    {this.state.runingNum > 0 && (
                      <div className="vod-footer">
                        <span>QR Code expire in</span>
                        <h2>{this.state.runingNum}</h2>
                        <p>Seconds</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.paymentMethod !== "QR Payment" &&
            this.state.paymentMethod !== "voucherpay" && this.state.paymentMethod!=="" && (
              <IonFooter collapse="fade">
                <div className="sticky-single-btn">
                  <a
                    href={() => false}
                    className="button green-btn place-order-link"
                    id="placeOredr"
                    onClick={this.payBill.bind(this)}
                  >
                    Pay with{" "}
                    <span>
                      {(() => {
                        if (this.state.paymentMethod === "mv3ds") {
                          return (
                            <>
                              <img
                                src={mastercard}
                                alt="Master"
                                className="master-card"
                              />
                              <img
                                src={visa}
                                alt="Visa"
                                className="visa-card"
                              />
                            </>
                          );
                        } else if (this.state.paymentMethod === "applepay") {
                          return (
                            <img
                              src={applePay}
                              alt="ApplePay"
                              className="apple-icon"
                            />
                          );
                        } else if (this.state.paymentMethod === "amex3ds") {
                          return (
                            <img
                              src={amexPay}
                              alt="ApplePay"
                              className="amexpay-icon"
                            />
                          );
                        } else if (this.state.paymentMethod === "googlepay") {
                          return (
                            <img
                              src={googlePay}
                              alt="ApplePay"
                              className="googlepay-icon"
                            />
                          );
                        } else if (this.state.paymentMethod === "atome") {
                          return (
                            <img
                              src={atome}
                              alt="ApplePay"
                              className="atome-icon"
                            />
                          );
                        } else if (this.state.paymentMethod === "paynow") {
                          return (
                            <img
                              src={paynow}
                              alt="ApplePay"
                              className="paynow-icon"
                            />
                          );
                        } else if (this.state.paymentMethod === "grabpay") {
                          return (
                            <img
                              src={grabpay}
                              alt="ApplePay"
                              className="grabpay-icon"
                            />
                          );
                        } else if (this.state.paymentMethod === "MSwipe") {
                          return (
                            <img
                              src={mswipe}
                              alt="ApplePay"
                              className="mswipe-icon"
                            />
                          );
                        }
                      })()}
                      <label>Pay</label>
                    </span>
                  </a>
                  <div>
                    <span className="error">{this.state.error_msg}</span>
                  </div>
                </div>
              </IonFooter>
            )}

          <BottomSheet
            open={this.state.signFrm}
            onDismiss={() => {
              this.setState({ signFrm: false });
            }}
          >
            <div className="container">
              {this.state.bottompopup === "regphone" && (
                <div className="welcome-phone">
                  <div className="wp-top">
                    <h2>Welcome to EpicPay</h2>
                    <p>Enter your phone number to create your account</p>
                  </div>
                  <div className="input-phone">
                    <div className="prefix-merge">
                      <div className="prefix">+65</div>
                      <div className="nxt-fix">
                        <input
                          type="input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="reg_phone_no"
                          value={this.state.reg_phone_no}
                          placeholder="Phone number"
                          onChange={this.handleFldChange.bind(this)}
                        />
                      </div>
                    </div>
                    {this.state.reg_phone_no_error !== "" && (
                      <span className="error-info">
                        {this.state.reg_phone_no_error}
                      </span>
                    )}
                    <p>
                      We will send you a one-time SMS, Additional carriers
                      charges may apply
                    </p>
                  </div>
                  <div className="bottom-btn bb-txt textcenter">
                    <a
                      href="/"
                      className="button full-btn reg-otp-link"
                      onClick={this.regSendOtp.bind(this, "register")}
                    >
                      Send OTP
                    </a>
                    <p>
                      Do you have an account ?{" "}
                      <a
                        href="/"
                        onClick={this.goBackTo.bind(this, "loginfrm")}
                      >
                        Login here
                      </a>
                    </p>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "confirm" && (
                <div className="update-personal">
                  <div className="up-header confirm-header textcenter">
                    <h2>
                      You have entered<br></br>+65 {this.state.reg_phone_no}
                    </h2>
                    <img src={cellphone1} alt="phone" />
                  </div>
                  <div className="up-form confirm-phone">
                    <p>
                      A one-time verification code will be sent to this mobile
                      number
                    </p>
                    <p>
                      Press ‘Confirm’ to proceed or ‘Edit’ to amend your
                      details.
                    </p>
                    {this.state.regcust_error !== "" && (
                      <p className="error_info">{this.state.regcust_error}</p>
                    )}
                    <div className="up-frm-btn">
                      <a
                        href="/"
                        className="button ghost-btn"
                        onClick={this.goBackTo.bind(this, "regphone")}
                      >
                        Edit
                      </a>
                      <a
                        href="/"
                        className="button cust-reg-btn"
                        onClick={this.confirmPhone.bind(this)}
                      >
                        Confirm
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "regotp" && (
                <div className="enter-otp">
                  <h2>Enter OTP</h2>
                  <div className="four-bx-col">
                    <div className="four-bx">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt1"
                        id="reg_otp_dgt1"
                        value={this.state.regotpdata.reg_otp_dgt1}
                        onChange={this.handleFldChange.bind(this)}
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt2"
                        id="reg_otp_dgt2"
                        value={this.state.regotpdata.reg_otp_dgt2}
                        onChange={this.handleFldChange.bind(this)}
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt3"
                        id="reg_otp_dgt3"
                        value={this.state.regotpdata.reg_otp_dgt3}
                        onChange={this.handleFldChange.bind(this)}
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt4"
                        id="reg_otp_dgt4"
                        value={this.state.regotpdata.reg_otp_dgt4}
                        onChange={this.handleFldChange.bind(this)}
                      />
                    </div>
                    {this.displayRunNumbar()}
                  </div>
                  {this.state.trigger_otp !== "Yes" && (
                    <div className="otp-checkbox">
                      <ul>
                        <li>
                          <input
                            type="checkbox"
                            name="regotp_terms"
                            onChange={this.changeRegOtpChkBox.bind(
                              this,
                              "trams"
                            )}
                            checked={this.chakRegOtpChkBox("trams")}
                          />
                          <p>
                            I confirm I have read and accept the{" "}
                            <a href="/">Terms & Conditions</a>,{" "}
                            <a href="/">Privacy Policy</a> and the{" "}
                            <a href="/">PDPA consent</a>.
                          </p>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name="regotp_promo"
                            onChange={this.changeRegOtpChkBox.bind(
                              this,
                              "promo"
                            )}
                            checked={this.chakRegOtpChkBox("promo")}
                          />
                          <p>
                            I'd like to receive news, updates and promotions
                            from Buzzr via email and sms
                          </p>
                        </li>
                      </ul>
                    </div>
                  )}
                  {this.state.trigger_otp === "Yes" ? (
                    <div className="up-frm-btn">
                      <a
                        href="/"
                        className="button ghost-btn"
                        onClick={this.cancelAct.bind(this)}
                      >
                        Cancel
                      </a>
                      <a
                        href="/"
                        className="button cust-reg-btn"
                        onClick={this.tgrVerifyOtp.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  ) : (
                    <div className="bottom-btn textcenter">
                      <a
                        href="/"
                        className="button full-btn reg-otp-verify"
                        onClick={this.regVerifyOtp.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  )}
                </div>
              )}

              {this.state.bottompopup === "regpersonal" && (
                <div className="update-personal">
                  <div className="up-header textcenter">
                    <p>Update your personal details</p>
                  </div>
                  <div className="up-form">
                    <input
                      type="text"
                      placeholder="Name"
                      name="cust_name"
                      value={this.state.cust_name}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="cust_email_id"
                      value={this.state.cust_email_id}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <select
                      name="birthday_month"
                      value={this.state.birthday_month}
                      className="components_selct components_selctbox_cls"
                      onChange={this.handleFldChange.bind(this)}
                    >
                      <option value="">Birthday Month</option>
                      <option value="january">January</option>
                      <option value="february">February</option>
                      <option value="march">March</option>
                      <option value="april">April</option>
                      <option value="may">May</option>
                      <option value="june">June</option>
                      <option value="july">July</option>
                      <option value="august">August</option>
                      <option value="september">September</option>
                      <option value="october">October</option>
                      <option value="november">November</option>
                      <option value="december">December</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Referral Code"
                      name="cust_ref_code"
                      value={this.state.cust_ref_code}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    {this.state.regcust_error !== "" && (
                      <p className="error_info">{this.state.regcust_error}</p>
                    )}
                    <div className="up-frm-btn">
                      <a
                        href="/"
                        className="button ghost-btn"
                        onClick={this.goBackTo.bind(this, "regphone")}
                      >
                        Cancel
                      </a>
                      <a
                        href="/"
                        className="button cust-reg-btn"
                        onClick={this.custRegistration.bind(this)}
                      >
                        Register
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "loginfrm" && (
                <div className="welcome-phone login-frm">
                  <div className="wp-top">
                    <h2>Login to EpicPay</h2>
                    <p>Enter your phone number to and login to your account</p>
                  </div>
                  <div className="input-phone">
                    <div className="prefix-merge">
                      <div className="prefix">+65</div>
                      <div className="nxt-fix">
                        <input
                          type="input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="reg_phone_no"
                          value={this.state.reg_phone_no}
                          placeholder="Phone number"
                          /* onChange={this.handleFldChange.bind(this)} */
                        />
                      </div>
                    </div>
                    {this.state.regphone_error !== "" && (
                      <span className="error-info">
                        {this.state.regphone_error}
                      </span>
                    )}
                    <p>
                      We will send you a one-time SMS, Additional carriers
                      charges may apply
                    </p>
                  </div>
                  <div className="bottom-btn bb-txt textcenter">
                    <a
                      href="/"
                      className="button full-btn login-otp-link"
                      /* onClick={this.regSendOtp.bind(this, "login")} */
                    >
                      Login
                    </a>
                    <p>
                      Do not have an account ?{" "}
                      <a
                        href="/"
                        /* onClick={this.goBackTo.bind(this, "regphone")} */
                      >
                        Register here
                      </a>
                    </p>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "loginotp" && (
                <div className="enter-otp enter-otp-login">
                  <h2>Enter OTP</h2>
                  <div className="four-bx-col">
                    <div className="four-bx">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt1"
                        id="reg_otp_dgt1"
                        value={this.state.regotpdata.reg_otp_dgt1}
                        /* onChange={this.handleFldChange.bind(this)} */
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt2"
                        id="reg_otp_dgt2"
                        value={this.state.regotpdata.reg_otp_dgt2}
                        /* onChange={this.handleFldChange.bind(this)} */
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt3"
                        id="reg_otp_dgt3"
                        value={this.state.regotpdata.reg_otp_dgt3}
                        /* onChange={this.handleFldChange.bind(this)} */
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt4"
                        id="reg_otp_dgt4"
                        value={this.state.regotpdata.reg_otp_dgt4}
                        /*  onChange={this.handleFldChange.bind(this)} */
                      />
                    </div>
                    {/* this.displayRunNumbar() */}
                  </div>
                  <div className="bottom-btn">
                    <a
                      href="/"
                      className="button full-btn"
                      /* onClick={this.loginVerifyOtp.bind(this)} */
                    >
                      Login
                    </a>
                  </div>
                </div>
              )}
            </div>
          </BottomSheet>
        </div>
      );
    } else {
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div className="lds-hourglass"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  var productlistArr = [];
  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0].result_set;
    }
  }
  return {
    customerdetails: customerdetailsArr,
    productlist: productlistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCT_LIST, params });
    },
  };
};

Checkout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateTopProps, mapDispatchToProps)(Checkout)
);

/* export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Checkout));
 */
