import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { apiUrl, baseUrl } from "../Settings/Config";
import $ from "jquery";
var qs = require("qs");
class DirectPay extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, 'this.props')
    const QRCode = this.props.match.params?.QRCode||'';
    const outletSlug = this.props.match.params?.outletSlug||'';
    var userID = cookie.load('UserId') || '';
    var DirectQRCode = cookie.load('DirectQRCode') || '';
    
    this.state = {
      QRCode:QRCode,
      outletSlug:outletSlug,
      userID:userID
    };
    /* if(DirectQRCode!=="") {
      this.props.history.push('/directpay');
    }
    else  */if(QRCode==='') {
      this.props.history.push('/');
    }else {
      this.checkQRCode();
    }
  }

 
  checkQRCode() {
      var postObject = {
        QRCode:this.state.QRCode
      };
      if(this.state.userID!=="") {
        postObject['customerID'] = this.state.userID;
      }
      axios
        .post(apiUrl + "customer/loginWithQR", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            let custArr = res.data.result;
            cookie.save("DirectQRCode", this.state.QRCode, { path: "/" });
            localStorage.setItem('company_app_id', custArr.UniqueID);
              localStorage.setItem('company_logo', custArr.company_logo);
             
              cookie.save("UserMobile", custArr.customer_phone, { path: "/" });
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save("outletID", custArr.outletID, { path: "/" });
              cookie.save("company_app_id", custArr.UniqueID, { path: "/" });
              if(custArr.customer_default==='1') {                
                cookie.save("loginType", 'Guest', { path: "/" });
              }    
              
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
          
              setTimeout(() => {
                if(custArr.customer_default==='1') {
                  window.location.href = baseUrl + "directpay";
                }else {
                  window.location.href = baseUrl + "myaccount";
                }
                this.setState({ position: 1 });
              }, 100);
          } else {
            this.setState({ regphone_error: res.data.message });
          }
        });
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
   
  }

  render() {
   
      return (
        <div className="container">
          <div id="loading-indicator">
            <div className="lds-hourglass"></div>
          </div>
        </div>
      );
  }
}

export default DirectPay;
